import React, { memo, ReactElement, useEffect, useMemo, useState } from 'react';
import {
  ExistingProject,
  Expertise,
  LocalProjects,
  NewProject,
  ProfileViewMode,
} from '@src/stores/Profile/models';
import { BorderColors, Colors, Icon, IconType } from '@ateams/components';
import {
  getProjectId,
  getProjectMemberId,
  isNewExperienceItem,
  isProjectMemberUser,
} from '@src/views/Profile/helpers/experience';
import { Observer } from 'mobx-react';
import UploadImageModal from '@src/components/Modal/UploadImageModal';
import ProjectMembersModal from '@src/components/Modal/ProjectMembersModal';
import {
  ExperienceId,
  ExperienceMember,
  ExperienceMemberRole,
  ExperienceType,
} from '@a_team/models/dist/ExperienceObject';
import { CollaboratorStatus, UserId } from '@a_team/models/dist/UserObject';
import { createUseStyles } from 'react-jss';
import { useStores } from '@src/stores';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import ConfirmModal from '@src/components/Modal/ConfirmModal';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { ApplicationStoreData } from '@src/stores/Application';
import { isDeepEqual } from '@src/helpers/propComparison';
import Section, { SectionProps } from '../partials/Section';
import { useGetProfileByUsername } from '@src/rq/profile';
import {
  useApplyProjectSuggestion,
  useGetProfileSuggestions,
} from '@src/rq/profileSuggestions';
import {
  BuilderSuggestionType,
  SuggestionActionType,
} from '@a_team/models/dist/ProfileEnhancements';
import EditProjectModalV2 from './EditProjectModalV2';
import ProjectCardV2 from '@src/components/ProjectCardV2/ProjectCardV2';
import ViewProjectModalV2 from './ViewProjectModalV2';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '@a_team/ui-components';
import { useIsMutating } from '@tanstack/react-query';
import { profileSuggestions } from '@src/rq/keys/profileSuggestions';
import { MAX_SELECTED_PROJECTS } from '@src/stores/Profile/Profile';
import { noop } from 'lodash';
import { RecommendedProject } from '@a_team/models/dist/MissionApplicationObject';

interface Props {
  projects: LocalProjects;
  recommendedProjects?: RecommendedProject[];
  allProfileSkills?: Expertise[];
  profileMode?: ProfileViewMode;
  hiddenProjects?: ApplicationStoreData['hiddenProjects'];
  selectedProjects?: ExperienceId[];
  onChange: (projects: LocalProjects) => void;
  onSaveProject: (project: ExistingProject) => void;
  onVisibilityChange?: (projectId: string, isHidden: boolean) => void;
  onAdd?: () => (ExistingProject | NewProject)[];
  onDelete?: (project: string) => Promise<void>;
  onQueryRoles?: () => Promise<Expertise[]>;
  onQueryIndustries?: () => Promise<Expertise[]>;
  getEmptyProject?: () => NewProject;
  updateProject: (project: ExistingProject) => Promise<void>;
  createProject: (projectData: NewProject) => Promise<void>;
  guidanceMessage?: ReactElement;
  username: string;
  disabled?: boolean;
  readonly?: boolean;
  canHide?: boolean;
  error?: boolean;
  currentUserId: UserId | null;
  withIcon?: boolean;
  type?: SectionProps['type'];
  isApplicationView: boolean;
}

const useStyles = createUseStyles({
  projectCardsContainer: {
    marginTop: 24,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(100%, max-content))',
    gap: 40,
  },
  projectCardsContainerForApplication: {
    marginTop: 24,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(250px, 100%))',
    gap: 16,
  },
  [`@media (min-width: 600px)`]: {
    projectCardsContainer: {
      gridTemplateColumns: 'repeat(auto-fit, 250px)',
    },
  },
  [`@media (max-width: 1188px)`]: {
    projectCardsContainerForApplication: {
      gridTemplateColumns: 'repeat(1, minmax(250px, 100%))',
    },
  },
  loadingSuggestions: {
    display: 'flex',
    maxWidth: 1360,
    border: `1px solid ${BorderColors.lighter}`,
    padding: 16,
    gap: 16,
    borderRadius: 8,
    color: Colors.secondaryDark,
  },
  addProject: {
    padding: '10px 12px !important',
    minWidth: '145px',
  },
});

const sortProjects = (
  a: ExistingProject | NewProject,
  b: ExistingProject | NewProject,
) => {
  // Projects with no start date should be shown last
  const aHasNoStartDate = a.startDate === undefined;
  const bHasNoStartDate = b.startDate === undefined;
  if (aHasNoStartDate && !bHasNoStartDate) return 1;
  if (!aHasNoStartDate && bHasNoStartDate) return -1;

  // Check if either project is still ongoing (no end date)
  const aOngoing = a.endDate === undefined;
  const bOngoing = b.endDate === undefined;

  // Sort by ongoing status
  if (aOngoing && bOngoing) {
    const aStartDate = new Date(a.startDate?.toString() || '');
    const bStartDate = new Date(b.startDate?.toString() || '');
    if (aStartDate > bStartDate) return -1;
    if (aStartDate < bStartDate) return 1;
    return a.title.localeCompare(b.title); // Fallback to alphabetical if start dates are equal
  }
  if (aOngoing) return -1;
  if (bOngoing) return 1;

  // Check if either project is a legacy project (no start and end date)
  const aLegacy = a.startDate === undefined && a.endDate === undefined;
  const bLegacy = b.startDate === undefined && b.endDate === undefined;

  // Sort by legacy status
  if (aLegacy && bLegacy) return a.title.localeCompare(b.title);
  if (aLegacy) return 1;
  if (bLegacy) return -1;

  // For projects with end dates, sort by end date, most recent first
  const aEndDate = new Date(a.endDate?.toString() || '');
  const bEndDate = new Date(b.endDate?.toString() || '');
  if (aEndDate > bEndDate) return -1;
  if (aEndDate < bEndDate) return 1;

  // If end dates are the same, sort alphabetically
  return a.title.localeCompare(b.title);
};

export const Projects = memo((props: Props): ReactElement => {
  const styles = useStyles(props);
  const analytics = useAnalytics();

  const { auth, users } = useStores();

  const {
    projects,
    recommendedProjects,
    hiddenProjects,
    selectedProjects,
    readonly,
    allProfileSkills,
    onChange,
    onVisibilityChange,
    onAdd,
    onDelete,
    onQueryRoles,
    onQueryIndustries,
    getEmptyProject,
    updateProject,
    createProject,
    canHide = false,
    error,
    currentUserId,
    username,
    profileMode,
    withIcon,
    type,
    guidanceMessage,
    isApplicationView,
  } = props;

  const [loading, setLoading] = useLoadingState();
  const [loadingSubmit, setLoadingSubmit] = useLoadingState();
  const [selectedProjectId, setSelectedProjectId] = useState<string>();
  const [backgroundModalOpen, setBackgroundModalOpen] = useState(false);
  const [logoModalOpen, setLogoModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState<string>();
  const location = useLocation();
  const [membersModalOpen, setMembersModalOpen] = useState(false);
  const [allIndustries, setAllIndustries] = useState<Expertise[]>([]);
  const [roles, setRoles] = useState<Expertise[]>([]);

  const { data: user } = useGetProfileByUsername(username);
  const { mutateAsync: applyProjectSuggestion } = useApplyProjectSuggestion();
  const { data: projectSuggestions } = useGetProfileSuggestions();
  const mutationCount = useIsMutating({
    mutationKey: profileSuggestions.enhanceProfileByUserId(currentUserId || ''),
  });
  const isEnhancingProfile = useMemo(() => mutationCount > 0, [mutationCount]);

  const queryParams = new URLSearchParams(location.search);
  const history = useHistory();

  useEffect(() => {
    if (queryParams.has('project')) {
      const projectId = queryParams.get('project');
      if (projectId && setSelectedProjectId) {
        setSelectedProjectId(projectId);
        setViewModalOpen(true);
      }
    }
  }, [queryParams, setSelectedProjectId]);

  useEffect(() => {
    if (roles.length || !onQueryRoles) return;
    onQueryRoles().then((res) => {
      setRoles(res);
    });
  }, [roles.length, onQueryRoles]);

  useEffect(() => {
    if (!onQueryIndustries) return;
    onQueryIndustries()
      .then((resp) => setAllIndustries(resp))
      .catch((err) => console.error(err));
  }, []);

  const roleOptions = useMemo(() => {
    return roles.map((role) => {
      return { label: role.name, value: role.id };
    });
  }, [roles]);

  const selectedProject: NewProject | ExistingProject | undefined =
    useMemo(() => {
      const existingProject = projects.find(
        (project) => getProjectId(project) === selectedProjectId,
      );
      return existingProject || (getEmptyProject && getEmptyProject());
    }, [selectedProjectId, projects.length, getEmptyProject]);

  const tooltipText = useMemo(() => {
    let tooltip;
    !projects.length && (tooltip = "Add projects you've worked on");
    error && (tooltip = 'You must have at least one project');
    return tooltip;
  }, [error, projects]);

  // New method for creating a project
  const handleAddClicked = async () => {
    if (onAdd) {
      const projects = onAdd();
      setSelectedProjectId(getProjectId(projects[0]));
      setEditModalOpen(true);
    }
  };

  const markProjectForDeletion = (project: ExistingProject) => {
    const projectsCopy = [...projects];
    const projectIndex = projects.findIndex(
      (existingProject) =>
        getProjectId(existingProject) === getProjectId(project),
    );
    const currentMemberIndex = project.members.findIndex(
      (member) => getProjectMemberId(member) === currentUserId,
    );
    const currentMember = { ...project.members[currentMemberIndex] };
    isProjectMemberUser(currentMember) && (currentMember.deleted = true);
    project.members[currentMemberIndex] = currentMember;
    projectsCopy[projectIndex] = project;

    onChange(projectsCopy);
  };

  const handleChange = (project: ExistingProject | NewProject) => {
    const updatedProjects = [...projects];
    const index = updatedProjects.findIndex(
      (item) => getProjectId(item) === getProjectId(project),
    );
    updatedProjects[index] = project;
    onChange(updatedProjects);
  };

  const handleRemove = (project: ExistingProject | NewProject) => {
    isNewExperienceItem(project)
      ? onChange(
          projects.filter(
            (item) =>
              !item.isSuggested && getProjectId(item) !== getProjectId(project),
          ),
        )
      : markProjectForDeletion(project);
  };

  const handleCardClick = (id: string) => {
    setSelectedProjectId(id);
    setViewModalOpen(true);

    history.replace(`${location.pathname}?project=${id}`);
  };

  const handleEditClicked = (id: string) => {
    analytics.trackProfileProjectEditClicked(id);
    setSelectedProjectId(id);
    setEditModalOpen(true);
  };

  const handleMembersChange = (members: ExperienceMember[]) => {
    if (!selectedProject) return;
    const project: NewProject | ExistingProject = {
      ...selectedProject,
      members,
    };
    handleChange(project);
    setMembersModalOpen(false);
  };

  const handleImageChange = (url: string, type: 'image' | 'logo') => {
    if (!selectedProject) return;
    const isImageModal = type === 'image';
    const project: NewProject | ExistingProject = {
      ...selectedProject,
      ...(isImageModal
        ? {
            imageURL: url,
          }
        : { logoURL: url }),
    };
    handleChange(project);
    isImageModal ? setBackgroundModalOpen(false) : setLogoModalOpen(false);
  };

  const handleOnConfirm = () => {
    projectToDelete && onDelete && setLoading(onDelete(projectToDelete));
    setProjectToDelete(undefined);
  };

  const isOwner = (project: ExistingProject | NewProject): boolean => {
    if (project.isSuggested) {
      return true;
    }

    const projectOwner = project.members.find(
      (member) => member.experienceRole === ExperienceMemberRole.Owner,
    );
    if (
      projectOwner &&
      'uid' in projectOwner &&
      projectOwner.uid === currentUserId
    ) {
      return true;
    } else {
      return false;
    }
  };

  const canEdit = auth.uid === currentUserId && !auth.readonlyAccess;

  const onSubmitProject = (project: NewProject | ExistingProject) => {
    if (
      project.isSuggested &&
      projectSuggestions?.projects?.sid &&
      selectedProjectId &&
      user?.uid
    ) {
      const suggestionId = projectSuggestions.projects.sid;
      const newProject = {
        ...project,
        type: ExperienceType.Project,
        members: [
          {
            username: user?.username,
            experienceRole: ExperienceMemberRole.Owner,
            collaboratorStatus: CollaboratorStatus.Active,
          },
        ],
      } as NewProject;
      setLoadingSubmit(
        applyProjectSuggestion({
          sid: suggestionId,
          eid: selectedProjectId,
          editedExperience: newProject,
        })
          .then(() => {
            createProject(newProject).then(() => {
              setSelectedProjectId(undefined);
              setEditModalOpen(false);
            });
            analytics.trackProfileSuggestionInteraction(
              user?.uid,
              suggestionId,
              SuggestionActionType.PARTIAL_ACCEPT,
              BuilderSuggestionType.PROJECTS,
              [newProject.title, newProject.description ?? ''],
            );
          })
          .catch((err) => {
            console.error(err);
          }),
      );
    } else {
      if (!isNewExperienceItem(project)) {
        setLoadingSubmit(
          updateProject(project).then(() => {
            setSelectedProjectId(undefined);
            setEditModalOpen(false);
          }),
        );
        if (project.isMissingMinimumRequirements) {
          handleSelectProject(project.eid);
        }
      } else {
        setLoadingSubmit(
          createProject(project).then(() => {
            setSelectedProjectId(undefined);
            setEditModalOpen(false);
          }),
        );
      }
    }
  };

  const isEditMode = profileMode === ProfileViewMode.Edit;

  const handleViewProjectModalV2Close = () => {
    history.push({ search: '' });
    setSelectedProjectId(undefined);
  };

  const handleSelectProject = (projectId: string) => {
    onVisibilityChange && onVisibilityChange(projectId, false);
  };

  const handleDeselectProject = (projectId: string) => {
    onVisibilityChange && onVisibilityChange(projectId, true);
  };

  const getContent = () => (
    <>
      {canEdit && isEditMode && (
        <Button
          onClick={handleAddClicked}
          className={styles.addProject}
          variant="secondary"
          data-testing-id="profile-project-add-button"
        >
          <Icon
            type={IconType.PlusBlack}
            style={{ marginRight: 8 }}
            size="xsmall"
          />
          Add a project
        </Button>
      )}
      {guidanceMessage}
      <div
        className={
          isApplicationView
            ? styles.projectCardsContainerForApplication
            : styles.projectCardsContainer
        }
      >
        {projects
          .slice()
          .sort(sortProjects)
          .map((project, index) => {
            const projectId = getProjectId(project);
            let isHidden = hiddenProjects?.get(projectId) || false;

            const recommendation = recommendedProjects?.find(
              (recommendedProject) => recommendedProject.eid === projectId,
            );

            if (readonly && isHidden) {
              return null;
            }

            if (canHide && project.isSuggested) {
              return null;
            }

            const industry = allIndustries?.find(
              (item) =>
                item.id === project.industry || item.name === project.industry,
            );

            const industries = allIndustries.filter((item) =>
              project?.industries?.includes(item.id),
            );

            const projectIndustries = industries.map((item) => item.name);

            const projectCover =
              project.imagesUrls?.[0] || project.imageURL || undefined;

            const selectedProjectIndex =
              selectedProjects?.findIndex((id) => id === projectId) ?? 0;

            const canSelect = !selectedProjects?.length
              ? true
              : selectedProjects.length < MAX_SELECTED_PROJECTS;

            if (canHide && selectedProjectIndex < 0) {
              isHidden = true;
            }

            return (
              <ProjectCardV2
                key={projectId}
                index={index}
                canEdit={canEdit}
                isEditMode={isEditMode}
                canHide={canHide}
                isOwner
                onEdit={() => {
                  handleEditClicked(projectId);
                }}
                onDelete={() => {
                  setProjectToDelete(projectId);
                }}
                isSuggested={project.isSuggested}
                companyLogo={project.logoURL || ''}
                companyName={
                  project.companyName ?? project.companyData?.name ?? ''
                }
                projectCover={projectCover}
                projectName={project.title}
                projectIndustry={industry?.name || ''}
                projectIndustries={projectIndustries}
                startDate={project.startDate}
                endDate={project.endDate}
                projectId={projectId}
                onOpenProject={() => handleCardClick(projectId)}
                canSelect={canSelect}
                isHidden={isHidden}
                recommendation={recommendation?.matches}
                disabledOnHidden={!auth.withApplicationBuilderScoring}
                selectedPosition={(selectedProjectIndex ?? 0) + 1}
                onSelect={handleSelectProject}
                onDeselect={handleDeselectProject}
                isMissingMinimumRequirements={
                  project.isMissingMinimumRequirements
                }
              />
            );
          })}
      </div>
      <UploadImageModal
        title={'Background Image'}
        open={backgroundModalOpen}
        onClose={() => setBackgroundModalOpen(false)}
        onAccept={(url) => handleImageChange(url, 'image')}
        initialUrl={selectedProject?.imageURL}
        circularCrop={false}
        cropAspect={5}
        downscale={{ width: 1250, height: 200 }}
      />
      <UploadImageModal
        title={'Logo'}
        open={logoModalOpen}
        onClose={() => setLogoModalOpen(false)}
        onAccept={(url) => handleImageChange(url, 'logo')}
        initialUrl={selectedProject?.logoURL}
        circularCrop
        cropAspect={1}
        downscale={{ width: 600, height: 600 }}
      />
      <ProjectMembersModal
        members={
          selectedProject?.members?.filter((member) =>
            isProjectMemberUser(member),
          ) || []
        }
        open={membersModalOpen}
        onClose={() => setMembersModalOpen(false)}
        onAccept={handleMembersChange}
        currentUserId={currentUserId}
      />
      {selectedProject && user && (
        <EditProjectModalV2
          withProjectDescriptionAssistant={auth.withProjectDescriptionAssistant}
          allJobs={users?.profile?.jobs}
          allSkills={users?.profile?.allSkills}
          currentUserId={user.uid}
          setEditModalOpen={setEditModalOpen}
          editModalOpen={editModalOpen}
          selectedProject={selectedProject}
          isOwner={isOwner(selectedProject)}
          handleRemove={handleRemove}
          roles={roleOptions}
          onSubmitProject={onSubmitProject}
          onClose={() => setEditModalOpen(false)}
          loading={!!loadingSubmit}
        />
      )}

      {selectedProject && (
        <ViewProjectModalV2
          viewModalOpen={viewModalOpen}
          setViewModalOpen={setViewModalOpen}
          onClose={handleViewProjectModalV2Close}
          selectedProject={selectedProject as ExistingProject}
          allIndustries={allIndustries}
          allProfileSkills={allProfileSkills}
          projects={projects}
          hiddenProjects={hiddenProjects}
          onOpenProject={handleCardClick}
        />
      )}

      <ConfirmModal
        title={'Remove Project?'}
        actionLabel={'Remove'}
        actionTestingId={'profile-project-delete-modal-submit-button'}
        description={'Are you sure you want to remove this project?'}
        onConfirm={handleOnConfirm}
        onClose={() => setProjectToDelete(undefined)}
        open={projectToDelete !== undefined}
      />
      <LoadingIndicator loading={loading} />
    </>
  );

  return (
    <Observer>
      {() => (
        <>
          <Section
            iconType={withIcon ? IconType.SuitcaseGray : undefined}
            type={type}
            title="Projects"
            description={
              canHide
                ? `Choose two to three projects that best showcase your skills and experience relevant to this role.`
                : ''
            }
            tooltipText={tooltipText}
            readonly={readonly}
            disabledMaxWidth
            error={error}
          >
            {isEnhancingProfile && (
              <div className={styles.loadingSuggestions}>
                <Icon type={IconType.CircleLoadingViolet} rotating />
                Loading suggested projects
              </div>
            )}
            <div style={{ maxWidth: 1360 }}>{getContent()}</div>
          </Section>
          {auth.isAdmin && readonly && (hiddenProjects?.size ?? 0) > 0 && (
            <Section
              iconType={IconType.SuitcaseGray}
              type={type}
              title="Projects hidden from application"
              readonly
              disabledMaxWidth
            >
              <div
                className={
                  isApplicationView
                    ? styles.projectCardsContainerForApplication
                    : styles.projectCardsContainer
                }
              >
                {projects
                  .slice()
                  .sort(sortProjects)
                  .map((project, index) => {
                    const projectId = getProjectId(project);
                    const isHidden = hiddenProjects?.get(projectId) || false;

                    if (!isHidden) {
                      return null;
                    }
                    const industry = allIndustries?.find(
                      (item) =>
                        item.id === project.industry ||
                        item.name === project.industry,
                    );

                    const industries = allIndustries?.filter((item) =>
                      project?.industries?.includes(item.id),
                    );

                    const projectIndustries = industries.map(
                      (item) => item.name,
                    );

                    const projectCover =
                      project.imagesUrls?.[0] || project.imageURL || undefined;

                    return (
                      <ProjectCardV2
                        key={projectId}
                        index={index}
                        onEdit={noop}
                        onDelete={noop}
                        companyLogo={project.logoURL || ''}
                        companyName={project.companyData?.name || ''}
                        projectCover={projectCover}
                        projectName={project.title}
                        projectIndustry={industry?.name || ''}
                        projectIndustries={projectIndustries}
                        startDate={project.startDate}
                        endDate={project.endDate}
                        projectId={projectId}
                        onOpenProject={() => handleCardClick(projectId)}
                        isMissingMinimumRequirements={
                          project.isMissingMinimumRequirements
                        }
                      />
                    );
                  })}
              </div>
            </Section>
          )}
        </>
      )}
    </Observer>
  );
}, isDeepEqual);
