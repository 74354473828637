import { DateISOString } from './misc';
import { UserId } from './UserObject';
import { VettingProcessEmailTemplates } from './vetting-processes/emails';
import { VettingProcessSoftwareEngineeringPreVettingForm } from './vetting-processes/pre-vetting';
import { VettingProcessId } from './vetting-processes/vetting-processes';
import { ObjectID } from 'mongodb';

export type VettingProcessAuditId = string;

export interface VettingProcessAudit<
  T extends VettingProcessAuditTypes | unknown = unknown,
> {
  id: VettingProcessAuditId;
  vettingProcessId: VettingProcessId;
  user?: VettingProcessAuditUser;
  date?: DateISOString;
  auditType: T;
  payload: T extends VettingProcessAuditTypes
    ? VettingProcessAuditTypesPayloads[T]
    : unknown;
  automationReason?: VettingProcessAuditAutomationReason;
}

export enum VettingProcessAuditAutomationReason {
  Exceptional = 'Exceptional',
  AppliedToMission = 'AppliedToMission',
  AutomatedFirstFollowUp = 'AutomatedFirstFollowUp',
  AutomatedSecondFollowUp = 'AutomatedSecondFollowUp',
  InvitedByActiveBuilder = 'InvitedByActiveBuilder',
  SupplySourcing = 'SupplySourcing',
  ReferredByInterviewer = 'ReferredByInterviewer',
}

export interface VettingProcessNoShowHistory {
  vetter: ObjectID;
  interviewDate: DateISOString;
  calComBookingId: number;
  calComDailyMeetingId: string;
  isBuilderNoShow: boolean;
  createdAt: DateISOString;
}

export interface VettingProcessCancellationHistory {
  vetter: ObjectID;
  interviewDate: DateISOString;
  calComBookingId: number;
  calComDailyMeetingId: string;
  isBuilderCancelled: boolean;
  reason: string;
  createdAt: DateISOString;
  createdBy: ObjectID;
}

export interface VettingProcessAuditUser {
  id: UserId;
  email: string;
  fullName: string;
  profilePictureURL: string;
  profileURL: string;
}

/**
 * {@link https://buildateam.atlassian.net/browse/NEXUS-508}
 */
export enum VettingProcessAuditTypes {
  ProcessStarted = 'ProcessStarted',
  PreVettingFormSubmission = 'PreVettingFormSubmission',
  NotifyInterviewerOnProcess = 'NotifyInterviewerOnProcess',
  ProcessAcceptedByInterviewer = 'ProcessAcceptedByInterviewer',
  ProcessDeclinedByInterviewer = 'ProcessDeclinedByInterviewer',
  InterviewDateSet = 'InterviewDateSet',
  FeedbackFormSubmission = 'FeedbackFormSubmission',
  FeedbackFormEdited = 'FeedbackFormEdited',
  BuilderOptedOutOfVetting = 'BuilderOptedOutOfVetting',
  ProcessRemoved = 'ProcessRemoved',
  EvaluationInviteEmail = 'EvaluationInviteEmail' /** {@link https://buildateam.atlassian.net/browse/NEXUS-923} */,
  BuilderNoShow = 'BuilderNoShow',
  VetterNoShow = 'VetterNoShow',
  BuilderCanceled = 'BuilderCanceled',
  VetterCanceled = 'VetterCanceled',
}

type VettingProcessAuditTypesPayloads = {
  [VettingProcessAuditTypes.ProcessStarted]: ProcessStartedPayload;
  [VettingProcessAuditTypes.PreVettingFormSubmission]: PreVettingFormSubmissionPayload;
  [VettingProcessAuditTypes.NotifyInterviewerOnProcess]: NotifyInterviewerOnProcessPayload;
  [VettingProcessAuditTypes.ProcessAcceptedByInterviewer]: ProcessAcceptedByInterviewerPayload;
  [VettingProcessAuditTypes.ProcessDeclinedByInterviewer]: ProcessDeclinedByInterviewerPayload;
  [VettingProcessAuditTypes.InterviewDateSet]: InterviewDateSetPayload;
  [VettingProcessAuditTypes.FeedbackFormSubmission]: FeedbackFormSubmissionPayload;
  [VettingProcessAuditTypes.FeedbackFormEdited]: FeedbackFormEditedPayload;
  [VettingProcessAuditTypes.BuilderOptedOutOfVetting]: BuilderOptedOutOfVettingPayload;
  [VettingProcessAuditTypes.ProcessRemoved]: ProcessRemovedPayload;
  [VettingProcessAuditTypes.EvaluationInviteEmail]: EvaluationInviteEmail;
  [VettingProcessAuditTypes.BuilderNoShow]: BuilderNoShowPayload;
  [VettingProcessAuditTypes.VetterNoShow]: VetterNoShowPayload;
  [VettingProcessAuditTypes.BuilderCanceled]: BuilderCanceledPayload;
  [VettingProcessAuditTypes.VetterCanceled]: VetterCanceledPayload;
};

type ProcessStartedPayload = undefined;

interface PreVettingFormSubmissionPayload {
  formAnswers: VettingProcessSoftwareEngineeringPreVettingForm;
}

interface NotifyInterviewerOnProcessPayload {
  sentTo: VettingProcessAuditUser;
}

type ProcessAcceptedByInterviewerPayload = undefined;

interface ProcessDeclinedByInterviewerPayload {
  reason: string;
}

interface InterviewDateSetPayload {
  interviewDate?: DateISOString;
}

interface FeedbackFormSubmissionPayload {
  formAnswers: unknown;
}

interface FeedbackFormEditedPayload {
  formAnswers: unknown;
}

interface BuilderOptedOutOfVettingPayload {
  reason: string;
}

interface ProcessRemovedPayload {
  reason: string;
}

interface EvaluationInviteEmail {
  templateType: VettingProcessEmailTemplates;
}

interface BuilderNoShowPayload {
  noShowUser: VettingProcessAuditUser;
}
interface VetterNoShowPayload {
  noShowUser: VettingProcessAuditUser;
}

interface BuilderCanceledPayload {
  reason: string;
  canceledUser: VettingProcessAuditUser;
}
interface VetterCanceledPayload {
  reason: string;
  canceledUser: VettingProcessAuditUser;
}
