import { ServiceAuth, ServiceEndpoint } from './utils';
import {
  DateISOString,
  QueryNextToken,
  QueryRequestPayload,
  QueryResult,
} from '@a_team/models/dist/misc';
import MissionObject, {
  MissionAdminObject,
  MissionBillingPeriod,
  MissionCardObject,
  MissionCompanyRequest,
  MissionId,
  MissionLink,
  MissionManagerAccessMode,
  MissionStatus,
  MissionApplyStatus,
  MissionSearchResult,
  MissionInvoicingPeriod,
  MissionInvoicing,
  RecommendedMission,
  BasicMissionObject,
} from '@a_team/models/dist/MissionObject';
import { RoleCategoryId } from '@a_team/models/dist/RoleCategory';
import {
  TeammateSuggestionObject,
  UserId,
  UserSuggestedTeamUp,
  UserUsername,
} from '@a_team/models/dist/UserObject';
import MissionApplicationObject, {
  AdminMissionApplicationObject,
  ApplicationConnection,
  ApplicationMissingRequirementsResponse,
  CustomQuestionRecommendationRequest,
  MissionApplicationAnalysisObject,
  MissionApplicationData,
  MissionApplicationDraftObject,
  MissionApplicationId,
  MissionApplicationInternalStatus,
  MissionApplicationLowCompetitivenessReason,
  MissionApplicationPitchRequest,
  MissionApplicationPreview,
  MissionApplicationRejectionReason,
  MissionApplicationReviewStatus,
  MissionApplicationReviewStatusNotSelected,
  MissionApplicationReviewStatusOpportunityToUpdate,
  MissionApplicationReviewStatusOther,
  MissionApplicationReviewStatusWaitlisted,
  MissionApplicationStatus,
} from '@a_team/models/dist/MissionApplicationObject';
import {
  BasicMissionRole,
  ClientRoleQuestion,
  MissionRoleId,
  MissionRoleStatus,
  MissionRoleVisibility,
} from '@a_team/models/dist/MissionRole';
import MissionPaymentCycleObject, {
  BasicMissionPaymentCycleAdminObject,
  BasicMissionPaymentCycleObject,
  MissionPaymentCycleAdminObject,
  MissionPaymentCycleAdminReportObject,
  MissionPaymentCycleId,
} from '@a_team/models/dist/MissionPaymentCycleObject';
import {
  BasicInvoiceObject,
  InvoiceId,
} from '@a_team/models/dist/InvoiceObject';
import ContractObject, {
  BasicContractObject,
  ContractId,
} from '@a_team/models/dist/ContractObject';
import { MinimalRecommendationData } from '@a_team/models/dist/RecommendationObject';
import { UtcOffsetRange } from '@a_team/models/src/TimezoneObject';
import {
  TalentSkillId,
  TalentSkillRating,
} from '@a_team/models/dist/TalentCategories';
import { TalentIndustryId } from '@a_team/models/dist/TalentIndustry';
import {
  GlobalPaymentCycle,
  GlobalPaymentCycleId,
  GlobalPaymentCycleRole,
} from 'src/models';
import TimesheetObject, {
  TimesheetId,
} from '@a_team/models/src/TimesheetObject';
import { WorkingHoursMissionRoleSchema } from '@a_team/models/dist/WorkingHoursObject';
import { AccountId } from '@a_team/models/src/Account';
import BillingAccountObject, {
  BillingPaymentTerms,
} from '@a_team/models/dist/BillingAccount';
import { removeUndefinedValues } from '@ateams/service-utils';
import { ClientBillingInfo } from '@a_team/models/dist/BillingInfo';
import { DiscountId } from '@a_team/models/src/Discount';

export const BasePath = '/missions';

export interface MissionRoleData {
  rid?: MissionRoleId;
  createdAt?: DateISOString;
  requestedAt?: DateISOString;
  submittedAt?: DateISOString;
  publishedAt?: DateISOString;
  createdBy?: UserId;
  cid: RoleCategoryId;
  status?: MissionRoleStatus;
  headline: string;
  locations?: string[];
  user?: {
    username?: UserUsername;
    fullName?: string;
  } | null;
  hourlyRate?: number;
  monthlyRate?: number;
  margin?: number | null;
  marginVAT?: number;
  utcOffsetRange?: UtcOffsetRange;
  workingHours?: WorkingHoursMissionRoleSchema;
  availability?: BasicMissionRole['availability'];
  preferredSkills?: { talentSkillId: TalentSkillId }[];
  requiredSkills?: {
    talentSkillId: TalentSkillId;
    rating: TalentSkillRating;
  }[];
  visibility?: MissionRoleVisibility;
  builderRateMin?: number;
  builderRateMax?: number;
  showRateRangeToBuilders?: boolean;
  customQuestions?: ClientRoleQuestionData[];
  automatedStatusesDisabled?: boolean;
  builderMonthlyRateMin?: number;
  builderMonthlyRateMax?: number;
  isFullTimeRetainer?: boolean;
  collectMonthlyRate?: boolean;
  readyForReview?: boolean;
}

export interface ClientRoleQuestionData extends ClientRoleQuestion {
  createdAt?: DateISOString;
  updatedAt?: DateISOString;
}

export interface MissionData {
  shortCompanyDescription?: string;
  companyStory?: string;
  title: string;
  description: string;
  status?: MissionStatus;
  applyStatus?: MissionApplyStatus;
  internalDescription?: string;
  expectedDurationMonths?: number;
  attachedLinks?: MissionLink[] | null;
  logoURL?: string;
  videoURL?: string;
  roles: MissionRoleData[];
  hidden?: boolean;
  skipContracts?: boolean;
  promotedTags?: string[];
  billingPeriod?: MissionBillingPeriod;
  automaticInvoicingPeriod?: MissionInvoicingPeriod | null;
  clientMargin?: number;
  rolesMargin?: number;
  publisher?: UserUsername;
  managers?: {
    accessMode: MissionManagerAccessMode;
    username: UserUsername;
    excludeFromInvoiceEmails?: boolean;
    excludeFromTeamPulseEmails?: boolean;
    excludeFromBuilderFeedbackEmails?: boolean;
  }[];
  invoiceEmailGreeting?: string;
  invoicing?: MissionInvoicing;
  mainManagerUsername?: string;
  talentIndustryIds?: TalentIndustryId[];
  website?: string;
  missionSpecId?: string;
  owner?: UserUsername;
  bdOwners?: UserId[];
  internalMission?: boolean;
  accountId?: AccountId;
  billingInfo?: ClientBillingInfo;
  paymentTerms?: BillingPaymentTerms;
  hubspotDealId?: string;
}

export interface AdminViewMissionResponse {
  createdMissions: QueryResult<MissionCardObject>;
  publishedMissions: QueryResult<MissionCardObject>;
  pendingMissions: QueryResult<MissionCardObject>;
  runningMissions: QueryResult<MissionCardObject>;
  archivedMissions: QueryResult<MissionCardObject>;
  endedMissions: QueryResult<MissionCardObject>;
  scheduledToEndMissions: QueryResult<MissionCardObject>;
}

export interface ApplicantConnectionsResponse {
  connectionsCount: number;
  invitations: QueryResult<ApplicationConnection>;
  recommendations: QueryResult<ApplicationConnection>;
  recommendedBy: QueryResult<ApplicationConnection>;
}

export interface AdminMissionPaymentCycleSummarizeRole {
  rid: MissionRoleId;
  submitted: boolean; // if timesheet submitted
  totalMinutes: number;

  hourlyRate: number;
  totalPayments: number;
  totalVAT: number;
  totalFees: number;
  margin: number;
  marginVAT: number;
  originHourlyRate: number;
}

export interface AdminMissionPaymentCycleSummarizeDiscount {
  /**
   * The discount object id to be applied to the invoice (if client has a pre-defined discount)
   */
  id?: DiscountId;

  /**
   * The amount of the discount to be applied to the invoice
   */
  amount: number;

  /**
   * If a discount id is provided, the amount of credit to be deducted from the discont object credit.
   * This is useful when the client has a pre-defined discount but an admin wants to override the left credit.
   */
  claimedCredit?: number;

  /**
   * The discount reason to be displayed on the invoice
   */
  reason?: string;
}

export interface PaymentCycleInvoiceRequest {
  loadCanceled?: boolean;
  basedOnInvoiceId?: InvoiceId;
  clientDiscount?: AdminMissionPaymentCycleSummarizeDiscount;
  ignorePayee?: boolean;
  generateInvoice?: boolean;
  ignorePaymentCycleStatus?: boolean;
}

export interface PaymentCycleReopenRequest {
  memo?: string;
}

export interface ToggleRoleLookingForApplicationsData {
  rid: MissionRoleId;
  lookingForApplications: boolean;
}

export interface SetMissionApplicationsUnavailable {
  applicationIds: MissionApplicationId[];
}

export interface AdminMissionPaymentCycleSummarize {
  roles: AdminMissionPaymentCycleSummarizeRole[];
  billingAccount?: BillingAccountObject;
  clientDiscount?: AdminMissionPaymentCycleSummarizeDiscount;
  basedOnInvoiceId?: InvoiceId;

  // platformFee: number;
  clientFee: number;
  // rolesFee: number;
  // platformMargin: number; // 0.2 ==> 20%
  clientMargin: number;
  rolesMargin: number;
  totalMinutes: number;
  totalPayments: number;
  totalVAT: number;
  dueDate: DateISOString;
  paymentTerms?: string;

  submitted: boolean; // all timesheets submitted
  billable: boolean; // has billing customer
  closeable: boolean; // all above are true
}

export enum QueryOperator {
  AND = 'AND',
  OR = 'OR',
}

export interface GetMissionApplicationsWithAutomatedStatusesRequest {
  skip?: string;
  limit?: string;
  from?: string;
  to?: string;
}

export interface AddStatusToMissionApplication {
  reviewStatus: MissionApplicationReviewStatus;
}
export interface MissionApplicationStatusUpdate {
  reviewStatus?: MissionApplicationReviewStatus;
  status?: MissionApplicationStatus;
  internalStatus?: MissionApplicationInternalStatus;
  lowCompetitiveness?: MissionApplicationLowCompetitivenessReason[];
  rejectionReason?: MissionApplicationRejectionReason[];
  exclusive?: boolean;
  proposed?: boolean;
}

export interface BulkMissionApplicationStatusUpdate {
  itemsToUpdate: BulkMissionApplicationStatusUpdateItem[];
}

export interface BulkMissionApplicationStatusUpdateItem {
  aid: MissionApplicationId;
  status?: MissionApplicationStatus;
  internalStatus?: MissionApplicationInternalStatus;
  lowCompetitiveness?: MissionApplicationLowCompetitivenessReason[];
  rejectionReason?: MissionApplicationRejectionReason[];
  notSelected?: MissionApplicationReviewStatusNotSelected[];
  opportunityToUpdate?: MissionApplicationReviewStatusOpportunityToUpdate[];
  waitlisted?: MissionApplicationReviewStatusWaitlisted[];
  other?: MissionApplicationReviewStatusOther[];
  exclusive?: boolean;
  proposed?: boolean;
  reviewStatus?: MissionApplicationReviewStatus;
}
export interface MissionApplicationManuallyProposedData {
  proposedRates: MissionApplicationData['proposedRates'];
  proposalManuallySharedAt: DateISOString;
}

export interface MissionNotificationRoleData {
  rid: MissionRoleId;
  headline: string;
  toggle: boolean;
  skills?: string[];
  minimumRequiredAvailableHours?: number;
  dateAvailableFrom?: DateISOString;
  utcOffsetRange?: UtcOffsetRange;
  /**
   * @default false
   */
  shouldIncludeAllSkills?: boolean;
  countries?: string[];
  additionalRoleIds?: string[];
  workingHours?: WorkingHoursMissionRoleSchema;
}

export interface MissionNotificationData {
  roles: MissionNotificationRoleData[];
  subject: string;
  contentHTML: string;
  contentText?: string;
  videoURL?: string;
  /**
   * @default 'OR'
   */
  talentIndustryOperator?: QueryOperator;
  talentIndustryIds?: TalentIndustryId[];
}

export interface MissionNotificationMatchingUserCountByRoleData {
  rid: MissionRoleId;
  skills?: string[];
  minimumRequiredAvailableHours?: number;
  dateAvailableFrom?: string;
  utcOffsetFrom?: number;
  utcOffsetTo?: number;
  /**
   * @default 'OR'
   */
  talentIndustryOperator?: QueryOperator;
  talentIndustryIds?: string[];
  /**
   * @default false
   */
  shouldIncludeAllSkills?: boolean;
  countries?: string[];
  additionalRoleIds: string[];
}

export interface MissionNotificationMatchingUserCountByRoleResult {
  rid: MissionRoleId;
  count: number;
}

export interface MissionQueryParameters {
  filterByRoleCategories?: string[];
  filterByStatus?: MissionStatus[];
  filterByOpenRoles?: boolean;
  prioritiseRoleCategories?: string[];
}

export interface TargetedMissionNotificationStatus {
  status: {
    uid: UserId;
    sent: boolean;
  }[];
}

export interface AdminMissionsRequest extends QueryRequestPayload {
  status?: MissionStatus;
}

export interface AdminMissionsWithPrecomputedTeamsAndExtensionsResponse {
  mission: BasicMissionObject;
  numTeams: number;
  numExtensions: number;
}

export interface AdminQueryGlobalPaymentCycleRolesRequest
  extends QueryRequestPayload {
  minBilledMinutes?: number;
}

export interface CustomContractPayload {
  uid: UserId;
  rid: MissionRoleId;
  accountId: AccountId;
  downloadURL: string;
}

export interface CustomPlatformAgreementPayload {
  downloadURL: string;
}

// exported functions

export default class MissionsEndpoint extends ServiceEndpoint {
  public queryMissionsCards(
    auth: ServiceAuth,
    query: MissionQueryParameters = {},
  ): Promise<QueryResult<MissionCardObject>> {
    return this.fetch(auth, BasePath, { ...query });
  }

  public getMissionById(
    auth: ServiceAuth | null,
    mid: MissionId,
  ): Promise<MissionObject> {
    return this.fetch(auth, BasePath + `/${mid}`);
  }

  public adminGetMissionById(
    auth: ServiceAuth,
    mid: MissionId,
  ): Promise<MissionAdminObject> {
    return this.fetch(auth, BasePath + `/${mid}`, { admin: true });
  }

  public queryMissionPaymentCycles(
    auth: ServiceAuth,
    mid: MissionId,
    next?: QueryNextToken,
  ): Promise<QueryResult<BasicMissionPaymentCycleObject>> {
    return this.fetch(auth, BasePath + `/${mid}/payment-cycles`, { next });
  }

  public adminQueryMissionPaymentCycles(
    auth: ServiceAuth,
    mid: MissionId,
    next?: QueryNextToken,
  ): Promise<QueryResult<BasicMissionPaymentCycleAdminObject>> {
    return this.fetch(auth, BasePath + `/${mid}/payment-cycles`, {
      admin: true,
      next,
    });
  }

  public adminAssignMissionOwner(
    auth: ServiceAuth,
    mid: MissionId,
    owner: UserId,
  ): Promise<MissionAdminObject> {
    return this.fetch(auth, BasePath + `/${mid}/owner`, null, 'PATCH', {
      owner,
    });
  }

  public getMissionPaymentCycle(
    auth: ServiceAuth,
    mid: MissionId,
    yid: MissionPaymentCycleId,
  ): Promise<MissionPaymentCycleObject> {
    return this.fetch(auth, BasePath + `/${mid}/payment-cycles/${yid}`);
  }

  public adminGetMissionPaymentCycle(
    auth: ServiceAuth,
    mid: MissionId,
    yid: MissionPaymentCycleId,
  ): Promise<MissionPaymentCycleAdminObject> {
    return this.fetch(auth, BasePath + `/${mid}/payment-cycles/${yid}`, {
      admin: true,
    });
  }

  public adminSummarizeMissionPaymentCycle(
    auth: ServiceAuth,
    mid: MissionId,
    yid: MissionPaymentCycleId,
    request: PaymentCycleInvoiceRequest,
  ): Promise<AdminMissionPaymentCycleSummarize> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/payment-cycles/${yid}/summarize`,
      null,
      'post',
      request,
    );
  }

  public adminInvoiceMissionTimesheet(
    auth: ServiceAuth,
    mid: MissionId,
    sid: TimesheetId,
  ): Promise<TimesheetObject> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/timesheets/${sid}/invoice`,
      null,
      'post',
    );
  }

  public adminCloseMissionPaymentCycle(
    auth: ServiceAuth,
    mid: MissionId,
    yid: MissionPaymentCycleId,
    request: PaymentCycleInvoiceRequest,
  ): Promise<MissionPaymentCycleAdminObject> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/payment-cycles/${yid}/close`,
      null,
      'post',
      request,
    );
  }

  public adminGenerateMissionPaymentCycleInvoice(
    auth: ServiceAuth,
    mid: MissionId,
    yid: MissionPaymentCycleId,
  ): Promise<MissionPaymentCycleAdminObject> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/payment-cycles/${yid}/invoice`,
      null,
      'post',
    );
  }

  public adminGenerateMultipleMissionPaymentCyclesInvoices(
    auth: ServiceAuth,
    mid: MissionId,
  ): Promise<MissionPaymentCycleAdminObject[]> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/invoice-payment-cycles`,
      null,
      'post',
    );
  }

  public adminQueryRecentGlobalPaymentCycles(
    auth: ServiceAuth,
    next?: QueryNextToken,
  ): Promise<QueryResult<GlobalPaymentCycle>> {
    return this.fetch(auth, BasePath + `/admin/global-payment-cycles`, {
      next,
    });
  }

  public adminQueryGlobalPaymentCycleRoles(
    auth: ServiceAuth,
    id: GlobalPaymentCycleId,
    req?: AdminQueryGlobalPaymentCycleRolesRequest,
  ): Promise<QueryResult<GlobalPaymentCycleRole>> {
    return this.fetch(
      auth,
      BasePath + `/admin/global-payment-cycles/${id}/roles`,
      req,
    );
  }

  public adminPrepayGlobalPaymentCycleRole(
    auth: ServiceAuth,
    id: GlobalPaymentCycleId,
    sid: TimesheetId,
  ): Promise<GlobalPaymentCycleRole> {
    return this.fetch(
      auth,
      BasePath + `/admin/global-payment-cycles/${id}/roles/${sid}/prepay`,
      null,
      'post',
    );
  }

  public adminPrepayGlobalPaymentCycleRoleBulk(
    auth: ServiceAuth,
    id: GlobalPaymentCycleId,
    sids: TimesheetId[],
  ): Promise<GlobalPaymentCycleRole[]> {
    return this.fetch(
      auth,
      BasePath + `/admin/global-payment-cycles/${id}/prepay`,
      { sid: sids },
      'post',
    );
  }

  public adminReopenMissionPaymentCycle(
    auth: ServiceAuth,
    mid: MissionId,
    yid: MissionPaymentCycleId,
    request: PaymentCycleReopenRequest,
  ): Promise<MissionPaymentCycleAdminObject> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/payment-cycles/${yid}/reopen`,
      null,
      'post',
      request,
    );
  }

  public adminSendPaymentCycleReminderEmailToUser(
    auth: ServiceAuth,
    mid: MissionId,
    yid: MissionPaymentCycleId,
    uid: UserId,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/payment-cycles/${yid}/reminder-email/user/${uid}`,
      null,
      'post',
    );
  }

  public companyRequestTeam(data: MissionCompanyRequest): Promise<MissionId> {
    return this.fetch(
      null,
      BasePath + `/company/team-request`,
      null,
      'post',
      data,
    );
  }

  public createMission(
    auth: ServiceAuth,
    data: MissionData,
  ): Promise<MissionObject | MissionAdminObject> {
    return this.fetch(auth, BasePath, null, 'post', data);
  }

  public updateMission(
    auth: ServiceAuth,
    mid: MissionId,
    data: MissionData,
  ): Promise<MissionObject | MissionAdminObject> {
    return this.fetch(auth, BasePath + `/${mid}`, null, 'put', data);
  }

  public endUserRoleInMission(
    auth: ServiceAuth,
    mid: MissionId,
  ): Promise<null> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/role-status-ended`,
      null,
      'post',
    );
  }

  public toggleRoleLookingForApplications(
    auth: ServiceAuth,
    mid: MissionId,
    data: ToggleRoleLookingForApplicationsData,
  ): Promise<unknown> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/role/toggle-looking-for-applications`,
      null,
      'PATCH',
      data,
    );
  }

  public getMissionApplicationDraft(
    auth: ServiceAuth,
    rid: MissionRoleId,
  ): Promise<MissionApplicationDraftObject> {
    return this.fetch(auth, BasePath + `/roles/${rid}/ask-join`);
  }

  public applyMission(
    auth: ServiceAuth,
    rid: MissionRoleId,
    data: MissionApplicationData,
  ): Promise<MissionApplicationObject> {
    return this.fetch(
      auth,
      BasePath + `/roles/${rid}/ask-join`,
      null,
      'post',
      data,
    );
  }

  public getTeammateSuggestions(
    auth: ServiceAuth,
    mid: MissionId,
    rid: MissionRoleId,
    next?: QueryNextToken,
  ): Promise<QueryResult<TeammateSuggestionObject>> {
    return this.fetch(auth, BasePath + `/${mid}/roles/${rid}/suggested`, {
      next,
    });
  }

  public getNarrativeTeammateSuggestions(
    auth: ServiceAuth,
    mid: MissionId,
    rid: MissionRoleId,
    searchQuery?: string,
    next?: QueryNextToken,
  ): Promise<QueryResult<UserSuggestedTeamUp>> {
    return this.fetch(
      auth,
      BasePath +
        `/${mid}/roles/${rid}/suggested/narrative/${searchQuery ?? ''}`,
      {
        next,
      },
    );
  }

  public getMissionApplication(
    auth: ServiceAuth,
    mid: MissionId,
    aid: MissionApplicationId,
  ): Promise<MissionApplicationObject> {
    return this.fetch(auth, BasePath + `/${mid}/application/${aid}`);
  }

  public updateMissionApplication(
    auth: ServiceAuth,
    mid: MissionId,
    aid: MissionApplicationId,
    data: MissionApplicationData,
  ): Promise<MissionApplicationObject> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/application/${aid}`,
      null,
      'put',
      data,
    );
  }

  public analyzeMissionApplication(
    auth: ServiceAuth,
    aid: MissionApplicationId,
  ): Promise<MissionApplicationAnalysisObject> {
    return this.fetch(
      auth,
      BasePath + `/application/${aid}/analyze`,
      null,
      'put',
    );
  }

  public missionApplicationAnalyses(
    auth: ServiceAuth,
    aid: MissionApplicationId,
    next?: QueryNextToken,
  ): Promise<QueryResult<MissionApplicationAnalysisObject>> {
    return this.fetch(
      auth,
      BasePath + `/application/${aid}/analyses`,
      { next },
      'get',
    );
  }

  public updateMissionApplicationStatus(
    auth: ServiceAuth,
    mid: MissionId,
    aid: MissionApplicationId,
    update: MissionApplicationStatusUpdate,
  ): Promise<AdminMissionApplicationObject> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/application/${aid}/status`,
      null,
      'put',
      update,
    );
  }

  public bulkUpdateMissionApplicationStatus(
    auth: ServiceAuth,
    mid: MissionId,
    update: BulkMissionApplicationStatusUpdate,
  ): Promise<AdminMissionApplicationObject[]> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/application/bulk-status-update`,
      null,
      'PATCH',
      update,
    );
  }

  public updateMissionApplicationManuallyProposedStatus(
    auth: ServiceAuth,
    mid: MissionId,
    aid: MissionApplicationId,
    update: MissionApplicationManuallyProposedData,
  ): Promise<AdminMissionApplicationObject> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/application/${aid}/manually-proposed`,
      null,
      'put',
      update,
    );
  }

  /** @deprecated **/
  public getMissionApplications(
    auth: ServiceAuth,
    mid: MissionId,
    next?: QueryNextToken,
    limit?: number,
    onlyOpenRoles?: boolean,
  ): Promise<QueryResult<AdminMissionApplicationObject>> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/applications`,
      removeUndefinedValues({ next, limit, onlyOpenRoles }),
    );
  }

  public getMissionApplicationMissingRequirements(
    auth: ServiceAuth,
    aid: MissionApplicationId,
    next?: QueryNextToken,
  ): Promise<ApplicationMissingRequirementsResponse> {
    return this.fetch(
      auth,
      BasePath + `/application/${aid}/missing-requirements`,
      { next },
    );
  }

  public getMissionRolesApplications(
    auth: ServiceAuth,
    mid: MissionId,
  ): Promise<
    Record<MissionRoleId, QueryResult<AdminMissionApplicationObject>>
  > {
    return this.fetch(auth, BasePath + `/${mid}/roles-applications`);
  }

  public getMissionRoleApplications(
    auth: ServiceAuth,
    mid: MissionId,
    rid: MissionRoleId,
    next?: QueryNextToken,
  ): Promise<QueryResult<AdminMissionApplicationObject>> {
    return this.fetch(auth, BasePath + `/${mid}/roles/${rid}/applications`, {
      next,
    });
  }

  public getApplicantConnections(
    auth: ServiceAuth,
    mid: MissionId,
    aid: MissionApplicationId,
    next?: QueryNextToken,
  ): Promise<ApplicantConnectionsResponse> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/applications/${aid}/connections`,
      { next },
    );
  }

  public adminViewMissions(
    auth: ServiceAuth,
    filter?: string,
    extendedSearch?: boolean,
  ): Promise<AdminViewMissionResponse> {
    return this.fetch(auth, BasePath + `/admin`, { filter, extendedSearch });
  }

  public getActiveMissions(
    auth: ServiceAuth,
    next?: QueryNextToken,
  ): Promise<QueryResult<MissionCardObject>> {
    return this.fetch(auth, BasePath + `/query/active`, { next });
  }

  public getPendingMissions(
    auth: ServiceAuth,
    next?: QueryNextToken,
  ): Promise<QueryResult<MissionCardObject>> {
    return this.fetch(auth, BasePath + `/query/pending`, { next });
  }

  public adminGetMissions(
    auth: ServiceAuth,
    status: MissionStatus,
    next?: QueryNextToken,
  ): Promise<QueryResult<MissionCardObject>> {
    return this.fetch(auth, BasePath + `/admin/missions`, { next, status });
  }

  public getArchivedMissions(
    auth: ServiceAuth,
    next?: QueryNextToken,
  ): Promise<QueryResult<MissionCardObject>> {
    return this.fetch(auth, BasePath + '/query/archived', { next });
  }

  public adminQueryPassedMissionPaymentCycles(
    auth: ServiceAuth,
    next?: QueryNextToken,
  ): Promise<QueryResult<MissionPaymentCycleAdminReportObject>> {
    return this.fetch(auth, BasePath + `/admin/passed-payment-cycles`, {
      next,
    });
  }

  public adminPublishMission(
    auth: ServiceAuth,
    mid: MissionId,
  ): Promise<MissionAdminObject> {
    return this.fetch(auth, BasePath + `/${mid}/publish`, null, 'post');
  }

  public adminMissionSetRolesVisible(
    auth: ServiceAuth,
    mid: MissionId,
    roleIds: string[],
  ): Promise<MissionAdminObject> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/set-roles-visible`,
      null,
      'post',
      { roleIds },
    );
  }

  public adminArchiveMission(
    auth: ServiceAuth,
    mid: MissionId,
  ): Promise<MissionAdminObject> {
    return this.fetch(auth, BasePath + `/${mid}/archive`, null, 'post');
  }

  public listMissionInvoices(
    auth: ServiceAuth,
    mid: MissionId,
    next?: QueryNextToken,
  ): Promise<QueryResult<BasicInvoiceObject>> {
    return this.fetch(auth, BasePath + `/${mid}/invoices`, { next });
  }

  public listCustomerInvoices(
    auth: ServiceAuth,
    mid: MissionId,
    next?: QueryNextToken,
  ): Promise<QueryResult<BasicInvoiceObject>> {
    return this.fetch(auth, BasePath + `/${mid}/customer-invoices`, { next });
  }

  public listClientInvoices(
    auth: ServiceAuth,
    mid: MissionId,
    next?: QueryNextToken,
  ): Promise<QueryResult<BasicInvoiceObject>> {
    return this.fetch(auth, BasePath + `/${mid}/client-invoices`, { next });
  }

  public deleteMission(auth: ServiceAuth, mid: MissionId): Promise<void> {
    return this.fetch(auth, BasePath + `/${mid}`, null, 'delete');
  }

  public deleteApplication(
    auth: ServiceAuth,
    aid: MissionApplicationId,
  ): Promise<void> {
    return this.fetch(auth, BasePath + `/application/${aid}`, null, 'delete');
  }

  public getMissionContracts(
    auth: ServiceAuth,
    mid: MissionId,
    next?: QueryNextToken,
  ): Promise<QueryResult<BasicContractObject>> {
    return this.fetch(auth, BasePath + `/${mid}/contracts`, { next });
  }

  public getMissionContract(
    auth: ServiceAuth,
    mid: MissionId,
    sid: ContractId,
  ): Promise<ContractObject> {
    return this.fetch(auth, BasePath + `/${mid}/contracts/contract/${sid}`);
  }

  public signMissionContract(
    auth: ServiceAuth,
    mid: MissionId,
    sid: ContractId,
  ): Promise<ContractObject> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/contracts/${sid}/sign`,
      null,
      'post',
    );
  }

  public createCustomMissionContract(
    auth: ServiceAuth,
    mid: MissionId,
    payload: CustomContractPayload,
  ): Promise<ContractObject> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/contract`,
      null,
      'post',
      payload,
    );
  }

  public previewTeammateApplication(
    auth: ServiceAuth,
    requestedApplicantUserId: UserId,
    mid: MissionId,
  ): Promise<MissionApplicationPreview> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/preview/${requestedApplicantUserId}`,
      null,
      'get',
    );
  }

  public submitTeammateRecommendations(
    auth: ServiceAuth,
    mid: MissionId,
    aid: MissionApplicationId,
    recommendations: MinimalRecommendationData[],
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/applications/${aid}/recommend`,
      null,
      'post',
      { recommendations },
    );
  }

  public previewMissionNotification(
    auth: ServiceAuth,
    mid: MissionId,
    notification: MissionNotificationData,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/notification-preview`,
      null,
      'post',
      notification,
    );
  }

  public sendTargetedMissionNotification(
    auth: ServiceAuth,
    mid: MissionId,
    uids: UserId[],
    notification: MissionNotificationData,
  ): Promise<TargetedMissionNotificationStatus> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/targeted-notification`,
      null,
      'post',
      { ...notification, uids },
    );
  }

  public sendMissionNotification(
    auth: ServiceAuth,
    mid: MissionId,
    notification: MissionNotificationData,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/notification`,
      null,
      'post',
      notification,
    );
  }

  public getMissionNotificationMatchingUsersCountByRole(
    auth: ServiceAuth,
    mid: MissionId,
    input: MissionNotificationMatchingUserCountByRoleData,
    abortController?: AbortController,
  ): Promise<MissionNotificationMatchingUserCountByRoleResult> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/notification/${input.rid}/matching-user-count`,
      { ...input },
      'get',
      undefined,
      abortController,
    );
  }

  public adminFindMissions(
    auth: ServiceAuth,
    filter?: string,
    extendedSearch?: boolean,
  ): Promise<MissionSearchResult[]> {
    return this.fetch(auth, `${BasePath}/admin/search`, {
      filter,
      extendedSearch,
    });
  }

  public setMissionApplicationsUnavailable(
    auth: ServiceAuth,
    data: SetMissionApplicationsUnavailable,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + `/applications/unavailable`,
      null,
      'PATCH',
      data,
    );
  }

  /**
   * Checks if a user has applied to a mission
   * @param auth - Admins
   */
  public hasUserAppliedToMissions(
    auth: ServiceAuth,
    uid: UserId,
  ): Promise<boolean> {
    return this.fetch(
      auth,
      BasePath + '/applications/has-applied/' + uid,
      null,
      'get',
    );
  }

  /**
   * Update the role of a mission application
   * @param auth - Admins
   */
  public updateApplicationRole(
    auth: ServiceAuth,
    aid: MissionApplicationId,
    rid: MissionRoleId,
  ): Promise<AdminMissionApplicationObject> {
    return this.fetch(
      auth,
      BasePath + '/applications/update-role/',
      null,
      'post',
      { aid, rid },
    );
  }

  public createCustomPlatformAgreement(
    auth: ServiceAuth,
    mid: MissionId,
    payload: CustomPlatformAgreementPayload,
  ): Promise<ContractObject> {
    return this.fetch(
      auth,
      BasePath + `/${mid}/contract/create`,
      null,
      'post',
      payload,
    );
  }

  /**
   * Set the performance flag of a role
   * @param auth - Admins
   * @param rid - The role Id
   * @param hasPerformanceIssue - The value of the performance flag
   */
  public setRolePerformanceFlag(
    auth: ServiceAuth,
    rid: MissionRoleId,
    hasPerformanceIssue: boolean,
  ): Promise<void> {
    return this.fetch(
      auth,
      BasePath + `/roles/${rid}/set-performance-flag`,
      null,
      'PATCH',
      { hasPerformanceIssue },
    );
  }

  public getRecommendedMissions(
    auth: ServiceAuth,
    limit?: number,
  ): Promise<QueryResult<RecommendedMission>> {
    return this.fetch(
      auth,
      BasePath + `/query/recommended`,
      removeUndefinedValues({ limit }),
    );
  }

  public generatePitchSuggestion(
    auth: ServiceAuth,
    mid: MissionId,
    rid: MissionRoleId,
    data: MissionApplicationPitchRequest,
  ): Promise<{ response: string }> {
    return this.fetch(
      auth,
      `${BasePath}/${mid}/roles/${rid}/generate-pitch`,
      null,
      'post',
      data,
    );
  }

  public generateQuestionRecommendation(
    auth: ServiceAuth,
    mid: MissionId,
    rid: MissionRoleId,
    data: CustomQuestionRecommendationRequest,
  ): Promise<{ response: string }> {
    return this.fetch(
      auth,
      `${BasePath}/${mid}/roles/${rid}/request-question-recommendation`,
      null,
      'post',
      data,
    );
  }
}
