import {
  BasicExperienceObject,
  ExperienceData,
  JobExperienceData,
  ProjectExperienceData,
} from './ExperienceObject';
import {
  TalentSpecialization,
  UserTalentSkillAssignment,
} from './TalentCategories';
import { BuilderData } from './LinkedIn';
import UserObject from './UserObject';
import { Company } from './Company';
import { BuilderResume } from './Resume';

export type BuilderSuggestionId = string;

export enum BuilderSuggestionType {
  BIO = 'BIO',
  PROJECTS = 'PROJECTS',
  SKILLS = 'SKILLS',
  ROLES = 'ROLES',
  JOBS = 'JOBS',
  INDUSTRIES = 'INDUSTRIES',
}

export interface BaseBuilderSuggestion {
  sid: BuilderSuggestionId;
  suggestionType: BuilderSuggestionType;
  creationDate: Date;
  action?: SuggestionAction;
}

export interface BioSuggestion extends BaseBuilderSuggestion {
  bio: string;
}

export interface ProjectsSuggestion extends BaseBuilderSuggestion {
  projects: Array<
    BasicExperienceObject & ProjectExperienceData & { rejected?: boolean }
  >;
}

export interface SkillsSuggestion extends BaseBuilderSuggestion {
  skills: Array<
    UserTalentSkillAssignment & { isFeatured?: boolean; rejected?: boolean }
  >;
}

export interface RolesSuggestion extends BaseBuilderSuggestion {
  roles: Array<TalentSpecialization & { rejected?: boolean }>;
}

export interface JobsSuggestion extends BaseBuilderSuggestion {
  jobs: Array<
    BasicExperienceObject &
      JobExperienceData & { rejected?: boolean } & {
        companyData?: Company;
      }
  >;
}

export interface IndustriesSuggestion extends BaseBuilderSuggestion {
  industries: Array<{
    id: string;
    name: string;
    rejected?: boolean;
  }>;
}

export type EditedSuggestion =
  | Pick<BioSuggestion, 'bio'>
  | Pick<ProjectsSuggestion, 'projects'>
  | Pick<SkillsSuggestion, 'skills'>
  | Pick<RolesSuggestion, 'roles'>
  | Pick<JobsSuggestion, 'jobs'>
  | Pick<IndustriesSuggestion, 'industries'>;

export type BuilderSuggestion =
  | BioSuggestion
  | ProjectsSuggestion
  | SkillsSuggestion
  | RolesSuggestion
  | JobsSuggestion
  | IndustriesSuggestion;

export interface ProfileEnhancementSuggestions {
  bio?: BioSuggestion;
  projects?: ProjectsSuggestion;
  skills?: SkillsSuggestion;
  roles?: RolesSuggestion;
  jobs?: JobsSuggestion;
  industries?: IndustriesSuggestion;
  unenhanceableProfileReasons: string[];
}

export enum SuggestionActionType {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  PARTIAL_ACCEPT = 'PARTIAL_ACCEPT',
}

export interface SuggestionActionRequest {
  editedSuggestion?: EditedSuggestion;
}

export interface SuggestionAction {
  actionDate: Date;
  type: SuggestionActionType;
  editedSuggestion?: EditedSuggestion;
}

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T[P] extends Record<string, unknown>
    ? DeepPartial<T[P]>
    : T[P];
};

export type SuggestionSources = DeepPartial<UserObject> &
  DeepPartial<ExperienceData> &
  DeepPartial<BuilderData> &
  DeepPartial<{ resume?: BuilderResume }>;

export type SuggestionsToRefresh = {
  [key in BuilderSuggestionType]?: boolean;
};
