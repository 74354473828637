import { QueryNextToken, QueryResult } from '@a_team/models/dist/misc';
import { z } from 'zod';

const locksAnchors = new Map<unknown, Promise<unknown>>();

// exported functions

export function lockBy<T>(
  anchor: unknown,
  fetcher: () => Promise<T>,
): Promise<T> {
  let task = locksAnchors.get(anchor);

  if (!task) {
    task = fetcher().then(
      (res) => {
        locksAnchors.delete(anchor);
        return res;
      },
      (err) => {
        locksAnchors.delete(anchor);
        throw err;
      },
    );
  }

  return task as Promise<T>;
}

export function transformQueryResult<T, F>(
  result: QueryResult<F>,
  transform: (item: F) => T,
): QueryResult<T> {
  return {
    items: result.items.map(transform),
    next: result.next,
  };
}

export function appendQueryResult<T>(
  result: QueryResult<T>,
  prev?: QueryResult<T> | null,
): QueryResult<T> {
  if (!prev) {
    return result;
  }

  return {
    items: prev.items.concat(result.items),
    next: result.next,
  };
}

export function getQueryNextToken<T>(
  prev?: QueryResult<T> | null,
): QueryNextToken | null | undefined {
  if (!prev) {
    return undefined;
  }

  return prev.next;
}

export const removeTagsFromHtmlString = (htmlString: string): string => {
  const regex = /(<([^>]+)>)/gi;
  return htmlString.replace(regex, '');
};

export const filterOutBracketedSkills = (
  skills: {
    id: string;
    name: string;
    talentCategoryIds: string[];
    isProgrammingLanguage: NonNullable<boolean | undefined>;
  }[],
): {
  id: string;
  name: string;
  talentCategoryIds: string[];
  isProgrammingLanguage: NonNullable<boolean | undefined>;
}[] => {
  return skills.filter(
    (skill) => !skill.name.includes('[') && !skill.name.includes(']'),
  );
};

const localHourRangeSchema = z.object({
  startTime: z.number(),
  endTime: z.number(),
});

const workingHoursSchema = z.object({
  name: z.string(),
  utcOffset: z.number(),
  daily: z.union([
    z.tuple([localHourRangeSchema]),
    z.tuple([localHourRangeSchema, localHourRangeSchema]),
  ]),
  utcStandardHours: z.array(localHourRangeSchema).optional(),
  utcDaylightHours: z.array(localHourRangeSchema).optional(),
});

export const storedApplicationDataSchema = z.object({
  pitch: z.string().optional(),
  customQuestions: z.record(z.string(), z.string()).optional(),
  hourlyRateRange: z
    .object({
      min: z.number(),
      max: z.number(),
    })
    .optional(),
  monthlyRateRange: z
    .object({
      min: z.number(),
      max: z.number(),
    })
    .optional(),
  availability: z
    .object({
      startDate: z.string(),
      hoursPerWeek: z.number(),
      notes: z.string(),
    })
    .optional(),
  workingHours: workingHoursSchema.optional(),
  hiddenProjects: z.array(z.string()).optional(),
});
