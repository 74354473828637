import React from 'react';
import cx from 'classnames';
import { Icon } from '@a_team/ui-components';
import purpleCoin from './purpleCoin.svg';
import { useInviteStyles } from '.';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useClientInviteMutation } from '@src/rq/invite';
import { ClientInvitationRequestData } from '@ateams/api/dist/endpoints/Invitations';
import LoadingIndicator from '@src/components/LoadingIndicator';

const schema = z.object({
  companyName: z.string().min(1, 'Company name is required'),
  companyReferralName: z.string().min(1, 'Referral name is required'),
  referralContactTitle: z.string().min(1, 'Contact title is required'),
  referralContactEmail: z.string().email('Invalid email address'),
  details: z.string().optional(),
});

type CompanyFormValues = z.infer<typeof schema>;

const CompanyForm = () => {
  const [howReferralsWork, setHowReferralsWork] = React.useState(false);
  const styles = useInviteStyles();
  const clientInviteMutation = useClientInviteMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CompanyFormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      companyName: '',
      companyReferralName: '',
      referralContactTitle: '',
      referralContactEmail: '',
      details: '',
    },
  });

  const onSubmit = (data: CompanyFormValues) => {
    const clientInvitationData: ClientInvitationRequestData = {
      ...data,
    };

    clientInviteMutation.mutate(clientInvitationData, {
      onSuccess: (result) => {
        if (result.success) {
          reset();
        } else {
          console.info('Client invite was not successful');
        }
      },
    });
  };
  return (
    <form
      className={cx(
        styles.companyBuilderFormContainer,
        styles.companyFormContainer,
      )}
      onSubmit={handleSubmit(onSubmit)}
    >
      <img src={purpleCoin} alt="purpleCoin" />
      <div className={styles.formTitle}>Invite a Company</div>
      <div className={cx(styles.body, styles.greyText)}>
        Connect your network to A.Team and get compensated up to $100K for
        successful referrals
      </div>
      <div className={styles.infoContainer}>
        <button
          className={cx(styles.subtitle, styles.howReferralsWorkButton)}
          onClick={(e) => {
            e.preventDefault();
            setHowReferralsWork(!howReferralsWork);
          }}
        >
          How referrals work
          {howReferralsWork ? (
            <Icon name="arrowUp" size="sm" className={styles.icon} />
          ) : (
            <Icon name="arrowDown" size="sm" className={styles.icon} />
          )}
        </button>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://guide.a.team/community/referrals"
          className={cx(styles.body, styles.learnMoreLink)}
        >
          Learn more
        </a>
      </div>
      {howReferralsWork && (
        <div className={styles.steps}>
          <div className={styles.stepContainer}>
            <div>
              <div className={styles.step}>Step 1</div>
            </div>
            <div className={cx(styles.body, styles.stepDesc)}>
              Submit the form below with details about the contact at the
              company you’d like to refer.
            </div>
          </div>
          <div className={styles.stepContainer}>
            <div>
              <div className={styles.step}>Step 2</div>
            </div>
            <div className={cx(styles.body, styles.stepDesc)}>
              If the referral meets{' '}
              <a
                href="https://guide.a.team/community/referrals"
                className={styles.learnMoreLink}
              >
                our criteria
              </a>
              , we’ll ask for an introduction (and provide you with a template).
            </div>
          </div>
          <div className={styles.stepContainer}>
            <div>
              <div className={styles.step}>Step 3</div>
            </div>
            <div className={cx(styles.body, styles.stepDesc)}>
              If they onboard a team, you’ll earn 10% of their revenue from the
              first year, up to $100,000.
            </div>
          </div>
          <div className={styles.questionsStep}>
            Have questions? Reach out to{' '}
            <a href="mailto:refer@a.team" className={styles.learnMoreLink}>
              refer@a.team
            </a>
          </div>
        </div>
      )}

      <div className={styles.divider} />

      <div className={cx(styles.subtitle, styles.formIntro)}>
        Fill out the form below to refer a potential A.Team client:
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="companyName" className={styles.label}>
          Company name
        </label>
        <input
          id="companyName"
          className={styles.input}
          placeholder="Enter name"
          {...register('companyName')}
        />
        {errors.companyName && (
          <span className={styles.error}>{errors.companyName.message}</span>
        )}
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="companyReferralName" className={styles.label}>
          Company referral name
        </label>
        <input
          id="companyReferralName"
          className={styles.input}
          placeholder="Full name"
          {...register('companyReferralName')}
        />
        {errors.companyReferralName && (
          <span className={styles.error}>
            {errors.companyReferralName.message}
          </span>
        )}
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="referralContactTitle" className={styles.label}>
          Referral contact title
        </label>
        <input
          id="referralContactTitle"
          className={styles.input}
          placeholder="Full title or role"
          {...register('referralContactTitle')}
        />
        {errors.referralContactTitle && (
          <span className={styles.error}>
            {errors.referralContactTitle.message}
          </span>
        )}
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="referralContactEmail" className={styles.label}>
          Referral contact email
        </label>
        <input
          id="referralContactEmail"
          className={styles.input}
          placeholder="Email address"
          {...register('referralContactEmail')}
        />
        {errors.referralContactEmail && (
          <span className={styles.error}>
            {errors.referralContactEmail.message}
          </span>
        )}
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="details" className={styles.label}>
          Details
        </label>
        <p className={styles.formDesc}>
          Recently raised capital, your connection to the company, etc.
        </p>
        <textarea
          id="details"
          className={styles.textarea}
          placeholder="Enter details"
          {...register('details')}
        />
        {errors.details && (
          <span className={styles.error}>{errors.details.message}</span>
        )}
      </div>

      <div className={styles.btnContainer}>
        <button type="submit" className={styles.submitButton}>
          {clientInviteMutation.isLoading ? 'Submitting...' : 'Refer a company'}
        </button>
      </div>

      <LoadingIndicator
        loading={
          clientInviteMutation.isSuccess ? 'The invitation has been sent' : null
        }
      />

      <LoadingIndicator
        loading={
          clientInviteMutation.isError
            ? {
                name: 'Error',
                message: 'Something went wrong. Please try again.',
              }
            : null
        }
      />
    </form>
  );
};

export default CompanyForm;
