import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import qs from 'query-string';
import Loader from '@src/components/Loader';
import { loadProfileMatch } from '@src/url-loaders/loadProfileMatch';
import { useStores } from '@src/stores';
import WelcomeModal from '@src/components/Modal/WelcomeModal';
import { Location } from 'history';
import ProfileLayout from '@src/layouts/Profile';
import Main from '@src/views/Profile/Main';
import Sidebar from '@src/views/Profile/Sidebar';
import { ProfileViewMode } from '@src/stores/Profile/models';
import AdminNotesMain from '@src/views/AdminNotes/Main';
import AdminNotesSidebar from '@src/views/AdminNotes/Sidebar';
import {
  AdminNotesTabsState,
  useAdminNotesTabs,
} from '@src/views/AdminNotes/Tabs/hooks';
import AdminNotesTabs from '@src/views/AdminNotes/Tabs';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useLoadingState, { LoadingState } from '@src/hooks/useLoadingState';
import { useHistory } from 'react-router-dom';
import { Colors } from '@ateams/components';
import { createUseStyles } from 'react-jss';

export const profileViewLoader = loadProfileMatch;

interface Props {
  location: Location;
}

const ChildWrapper = (props: {
  showLoader: boolean;
  mode?: ProfileViewMode;
  loading: LoadingState;
  adminNotesTabs: AdminNotesTabsState;
  onWelcomeModalClose: () => void;
  welcomeModalOpen: boolean;
}) => {
  if (props.showLoader) {
    return <Loader />;
  }
  return (
    <>
      <WelcomeModal
        open={props.welcomeModalOpen}
        onClose={props.onWelcomeModalClose}
      />
      {props.mode !== ProfileViewMode.Admin ? (
        <Main />
      ) : (
        <AdminNotesMain tabsState={props.adminNotesTabs} />
      )}
      <LoadingIndicator loading={props.loading} />
    </>
  );
};

const useStyles = createUseStyles({
  adminViewProfileLayoutChildren: {
    background: Colors.backgroundWhite,
  },
  topBar: {
    background: Colors.backgroundWhite,
  },
});

const ProfileView = (props: Props): ReactElement => {
  const stores = useStores();
  const history = useHistory();
  const { location } = props;
  const styles = useStyles();
  const {
    auth,
    users: { profile, trackProfileView, acceptTeamInvite, fetchAdminNotes },
    registration: { approveTeamFromEmail },
  } = stores;
  const [welcomeModalOpen, setWelcomeModalOpen] = useState(false);
  const [loading, setLoading] = useLoadingState();

  const query = useMemo(() => qs.parse(location.search), [location.search]);
  const adminNotesTabs = useAdminNotesTabs();

  const isWelcome = useMemo(() => {
    return query.welcome === '1';
  }, [query.welcome]);

  const isConnect = useMemo(() => {
    return query.connect === '1';
  }, [query.connect]);

  const isEdit = useMemo(() => {
    return query.edit === '1';
  }, [query.edit]);

  const isPreview = useMemo(() => {
    return query.preview === '1';
  }, [query.preview]);

  const blockNavigation = useMemo(() => {
    if (profile?.application?.submitted) return false;
    return !profile?.readonly && !!profile?.isDirty;
  }, [profile?.application?.submitted, profile?.readonly, profile?.isDirty]);

  useEffect(() => {
    approveTeamFromEmail &&
      setLoading(
        acceptTeamInvite(approveTeamFromEmail),
        'Accepted team invite',
      );
  }, [approveTeamFromEmail]);

  useEffect(() => {
    isWelcome && setWelcomeModalOpen(true);
  }, [isWelcome]);

  useEffect(() => {
    isEdit &&
      profile?.isCurrentUser &&
      profile?.setProfileMode(ProfileViewMode.Edit);
  }, [profile?.isCurrentUser, isEdit]);

  useEffect(() => {
    profile && trackProfileView(profile.data.username, auth);
    return () => {
      profile?.setProfileMode(ProfileViewMode.View);
      profile?.setInitialData();
    };
  }, [profile?.data]);

  useEffect(() => {
    if (query.note && profile?.mode !== ProfileViewMode.Admin && profile) {
      fetchAdminNotes(profile);
      profile.setHighlightedAdminNoteId(query.note as string);
      history.push({
        pathname: location.pathname,
        search: qs.stringify({ ...query, note: undefined }),
      });
    }
  }, [location, profile]);

  const onWelcomeModalClose = (): void => {
    setWelcomeModalOpen(false);
  };

  const onNavigate = useCallback(
    (path: string) => {
      // Reset data and continue to other route
      profile?.setInitialData();
      profile?.application?.setInitialData();
      history.push(path);
    },
    [profile],
  );

  const sidebarComp = useMemo(() => {
    return profile?.mode === ProfileViewMode.Admin ? (
      <AdminNotesSidebar />
    ) : (
      <Sidebar fixed />
    );
  }, [profile?.mode]);

  const isAdminView = useMemo(
    () => profile?.mode === ProfileViewMode.Admin,
    [profile?.mode],
  );

  return (
    <ProfileLayout
      title={profile ? profile.data.fullName : 'Loading...'}
      autoConnectModal={isConnect}
      hideNavbar={isPreview}
      sidebar={sidebarComp}
      topBarContent={
        isAdminView && <AdminNotesTabs state={adminNotesTabs} navbar />
      }
      topBarClassName={isAdminView ? styles.topBar : undefined}
      childrenClassName={
        isAdminView ? styles.adminViewProfileLayoutChildren : undefined
      }
      blockNavigation={blockNavigation}
      navigate={onNavigate}
    >
      <ChildWrapper
        showLoader={!profile}
        mode={profile?.mode}
        loading={loading}
        adminNotesTabs={adminNotesTabs}
        onWelcomeModalClose={onWelcomeModalClose}
        welcomeModalOpen={welcomeModalOpen}
      />
    </ProfileLayout>
  );
};

export default observer(ProfileView);
