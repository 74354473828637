import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import {
  BorderRadius,
  FontSizes,
  FontWeights,
  Icon,
  IconType,
  Paddings,
  Spacing,
  TextColors,
  ToggleSwitch,
} from '@ateams/components';
import { Colors } from '@ateams/components';
import { useStores } from '@src/stores';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useLoadingState from '@src/hooks/useLoadingState';
import { REACT_APP_CALCOM_URL } from '@src/config';
import { CalComBookingFrequency } from '@a_team/models/dist/vetting-processes/calcom';
import { useWindowFocus } from '@src/hooks/useWindowFocus';
import { isEmpty } from 'lodash';
import { Prompt } from 'react-router-dom';

const useStyles = createUseStyles({
  title: {
    fontSize: FontSizes.extraLarge,
    fontWeight: FontWeights.semiBold,
  },
  subTitle: {
    fontSize: FontSizes.regular,
    marginTop: Spacing.small,
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.large,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 768,
    marginTop: Spacing.large,
  },
  cardTitle: {
    color: TextColors.regular,
    fontSize: FontSizes.regular,
    fontWeight: FontWeights.semiBold,
  },
  cardSubtitle: {
    color: TextColors.regular,
    fontSize: FontSizes.regular,
    lineHeight: '24px',
    paddingTop: Paddings.xsmall,
  },
  purpleButton: {
    height: 40,
    color: TextColors.secondaryDark,
    backgroundColor: Colors.secondaryDark,
    borderRadius: BorderRadius.medium,
    border: 'none',
    cursor: 'pointer',
    padding: '6.5px 16px',
    fontSize: FontSizes.small,
  },
  saveButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start', // Aligns the save button to the left
    marginTop: Spacing.medium,
  },
  addCalendarContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: Spacing.small,
  },
  noCalendarMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    itemsAlign: 'center',
    marginTop: Spacing.large,
    padding: Spacing.medium,
    backgroundColor: '#F7F7F7',
    borderRadius: BorderRadius.medium,
  },
  noCalendarInstalledContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: Spacing.medium,
  },
  noCalendarInstalledTitle: {
    color: TextColors.regular,
    fontSize: FontSizes.regular,
    fontWeight: FontWeights.semiBold,
  },
  noCalendarInstalledSubtitle: {
    color: TextColors.primaryLight,
    fontSize: FontSizes.xsmall,
  },
  calendarInstalledTitle: {
    color: TextColors.regular,
    fontSize: FontSizes.regular,
    fontWeight: FontWeights.semiBold,
  },
  calendarInstalledSubtitle: {
    color: TextColors.regular,
    fontSize: FontSizes.xsmall,
  },
  bookingFrequenciesContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: Spacing.large,
    gap: Spacing.small,
  },
  bookingFrequencyContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.small,
  },
  bookingFrequencyInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    border: `1px solid #DADADC`,
    borderRadius: BorderRadius.default,
    height: 32,
  },
  bookingFrequencyInput: {
    width: 64,
    border: 'none',
    paddingLeft: 12,
    fontSize: FontSizes.regular,
    color: TextColors.regular,
    borderRadius: BorderRadius.default,
  },
  bookingFrequencyLabel: {
    width: 104,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: Colors.backgroundLight,
    paddingLeft: 12,
    fontSize: FontSizes.regular,
    color: TextColors.regular,
    borderTopRightRadius: BorderRadius.default,
    borderBottomRightRadius: BorderRadius.default,
  },
  bookingFrequencyClearButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
});

const EvaluationSettingsView = () => {
  const styles = useStyles();
  const { evaluationSettingsUser } = useStores();
  const [loading, setLoading] = useLoadingState();
  const selectedCalendar = evaluationSettingsUser.settings?.selectedCalendar;
  const defaultSchedule = evaluationSettingsUser.settings?.schedule;
  const defaultEventType = evaluationSettingsUser.settings?.eventType;
  const isWindowFocused = useWindowFocus();

  useEffect(() => {
    if (isWindowFocused) {
      evaluationSettingsUser.loadData();
    }
  }, [isWindowFocused]);

  useEffect(() => {
    if (evaluationSettingsUser.settings?.isActive) {
      setInterviewerIsActive(true);
    }
  }, [evaluationSettingsUser.settings?.isActive]);

  const existingBookingFrequency = useMemo<CalComBookingFrequency>(() => {
    if (
      !defaultEventType?.bookingLimits ||
      isEmpty(defaultEventType?.bookingLimits)
    ) {
      return null;
    }
    const newBookingFrequency = JSON.parse(
      JSON.stringify(defaultEventType.bookingLimits),
    );
    return newBookingFrequency;
  }, [defaultEventType]);
  const [bookingFrequencyEnabled, setBookingFrequencyEnabled] = useState(false);
  const [interviewerIsActive, setInterviewerIsActive] = useState(
    evaluationSettingsUser.settings?.isActive || false,
  );

  const [bookingFrequency, setBookingFrequency] =
    useState<CalComBookingFrequency | null>(existingBookingFrequency);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const updateBookingFrequency = useCallback(
    (newFrequency: CalComBookingFrequency | null) => {
      setBookingFrequency(newFrequency);
      setHasUnsavedChanges(true);
    },
    [],
  );

  const resetUnsavedChanges = useCallback(() => {
    setHasUnsavedChanges(false);
  }, []);

  const toggleActive = async () => {
    const newInterviewerIsActive = !interviewerIsActive;
    setInterviewerIsActive(newInterviewerIsActive);
    setLoading(
      evaluationSettingsUser.enableDisableInterviewer(newInterviewerIsActive),
      'Interviewer status updated successfully',
    );
  };

  const saveBookingFrequency = async () => {
    if (defaultEventType) {
      const emptyBookingFrequency = {
        PER_DAY: undefined,
        PER_WEEK: undefined,
        PER_MONTH: undefined,
      };
      const validBookingFrequency = {
        PER_DAY: bookingFrequency?.PER_DAY
          ? bookingFrequency.PER_DAY
          : undefined,
        PER_WEEK: bookingFrequency?.PER_WEEK
          ? bookingFrequency.PER_WEEK
          : undefined,
        PER_MONTH: bookingFrequency?.PER_MONTH
          ? bookingFrequency.PER_MONTH
          : undefined,
      };

      setLoading(true, 'Saving booking frequency...');
      await evaluationSettingsUser.saveBookingFrequency(
        defaultEventType.id,
        bookingFrequencyEnabled ? validBookingFrequency : emptyBookingFrequency,
      );
      setLoading(false, 'Booking frequency saved successfully');
      resetUnsavedChanges();
    }
  };

  const BookingFrequencyComponent = ({
    value,
    setValue,
    label,
  }: {
    value: string;
    setValue: (text: string) => void;
    label: string;
  }) => {
    return (
      <div className={styles.bookingFrequencyContainer}>
        <div className={styles.bookingFrequencyInputContainer}>
          <input
            type="number"
            min={1}
            max={999}
            value={value}
            onChange={(e) => {
              const newValue = parseInt(e.target.value);
              if (newValue >= 1 || e.target.value === '') {
                setValue(e.target.value);
                setHasUnsavedChanges(true);
              }
            }}
            className={styles.bookingFrequencyInput}
          />
          <div className={styles.bookingFrequencyLabel}>{label}</div>
        </div>
        <button
          onClick={() => {
            setValue('');
            setHasUnsavedChanges(true);
          }}
          className={styles.bookingFrequencyClearButton}
        >
          Clear
        </button>
      </div>
    );
  };

  useEffect(() => {
    if (existingBookingFrequency) {
      setBookingFrequencyEnabled(true);
      setBookingFrequency(existingBookingFrequency);
    }
  }, [existingBookingFrequency]);

  const openCalComLinkWithAutoLogin = (path: string) => {
    window.open(
      `${REACT_APP_CALCOM_URL}/auth/login?autoLogin=true&callbackUrl=${encodeURIComponent(
        path,
      )}`,
      '_blank',
    );
  };

  const gotToAddACalendarClick = () => {
    openCalComLinkWithAutoLogin('/apps/google-calendar');
  };

  const gotToManageCalendarClick = () => {
    openCalComLinkWithAutoLogin('/apps/installed/calendar');
  };

  const goToWorkingHoursClick = () => {
    openCalComLinkWithAutoLogin(`/availability/${defaultSchedule?.id}`);
  };

  return (
    <div>
      <Prompt
        when={hasUnsavedChanges}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <div className={styles.title}>Calendar</div>
      <div className={styles.subTitle}>Manage your calendar settings.</div>
      <div className={styles.cardsContainer}>
        <div className={styles.card}>
          <div className={styles.noCalendarMainContainer}>
            <div className={styles.noCalendarInstalledContainer}>
              {selectedCalendar && <Icon type={IconType.Google} size="large" />}
              <div>
                <div
                  className={
                    selectedCalendar
                      ? styles.calendarInstalledTitle
                      : styles.noCalendarInstalledTitle
                  }
                >
                  {selectedCalendar
                    ? 'Google Calendar'
                    : 'No calendar installed'}
                </div>
                <div
                  className={
                    selectedCalendar
                      ? styles.calendarInstalledSubtitle
                      : styles.noCalendarInstalledSubtitle
                  }
                >
                  {selectedCalendar ? selectedCalendar.externalId : ''}
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button
                className={styles.purpleButton}
                onClick={() =>
                  selectedCalendar
                    ? gotToManageCalendarClick()
                    : gotToAddACalendarClick()
                }
              >
                <div className={styles.addCalendarContainer}>
                  <div>
                    {selectedCalendar
                      ? 'Manage calendar settings'
                      : 'Add a calendar'}
                  </div>
                  <Icon type={IconType.LinkArrow} />
                </div>
              </button>
            </div>
          </div>
        </div>

        {selectedCalendar && (
          <div className={styles.card}>
            <div className={styles.cardTitle}>Working hours</div>
            <div className={styles.cardSubtitle}>
              Configure days and time this event can be booked{' '}
            </div>
            <div className={styles.noCalendarMainContainer}>
              <div>
                <div className={styles.cardTitle}>
                  Manage your working hours
                </div>
                <div className={styles.cardSubtitle}>
                  Set up your working hours on Cal.com{' '}
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <button
                  className={styles.purpleButton}
                  onClick={goToWorkingHoursClick}
                >
                  <div className={styles.addCalendarContainer}>
                    <div>Adjust working hours</div>
                    <Icon type={IconType.LinkArrow} />
                  </div>
                </button>
              </div>
            </div>
          </div>
        )}

        {selectedCalendar && (
          <>
            <div className={styles.card}>
              <div className={styles.cardTitle}>Active</div>
              <div className={styles.cardSubtitle}>
                <ToggleSwitch
                  size={'small'}
                  checked={interviewerIsActive}
                  onChange={() => toggleActive()}
                  label={'This will enable / disable booking'}
                />
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardTitle}>Booking frequency</div>
              <div className={styles.cardSubtitle}>
                <ToggleSwitch
                  size={'small'}
                  checked={bookingFrequencyEnabled}
                  onChange={() => {
                    setBookingFrequencyEnabled(!bookingFrequencyEnabled);
                    setBookingFrequency(null);
                  }}
                  label="Limit the number of times you can be booked in any given time period"
                />
              </div>
              {bookingFrequencyEnabled && (
                <>
                  <div className={styles.bookingFrequenciesContainer}>
                    <BookingFrequencyComponent
                      value={bookingFrequency?.PER_DAY?.toString() || ''}
                      setValue={(text) =>
                        updateBookingFrequency({
                          ...bookingFrequency,
                          PER_DAY: text ? parseInt(text) : undefined,
                        })
                      }
                      label="Per day"
                    />
                    <BookingFrequencyComponent
                      value={bookingFrequency?.PER_WEEK?.toString() || ''}
                      setValue={(text) =>
                        updateBookingFrequency({
                          ...bookingFrequency,
                          PER_WEEK: text ? parseInt(text) : undefined,
                        })
                      }
                      label="Per week"
                    />
                    <BookingFrequencyComponent
                      value={bookingFrequency?.PER_MONTH?.toString() || ''}
                      setValue={(text) =>
                        updateBookingFrequency({
                          ...bookingFrequency,
                          PER_MONTH: text ? parseInt(text) : undefined,
                        })
                      }
                      label="Per month"
                    />
                  </div>
                </>
              )}
              <div className={styles.saveButtonContainer}>
                <button
                  className={styles.purpleButton}
                  onClick={saveBookingFrequency}
                >
                  Save Booking Frequency
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      <LoadingIndicator loading={loading} />
    </div>
  );
};

export default observer(EvaluationSettingsView);
