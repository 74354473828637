import { createUseStyles } from 'react-jss';
import React, { ReactElement, useMemo, useState } from 'react';
import {
  BorderColors,
  BorderRadius,
  CollapsibleContainer,
  Colors,
  FontSizes,
  FontWeights,
  Icon,
  IconType,
  Spacing,
  Tag,
  TagList,
  TextColors,
} from '@ateams/components';
import {
  MatchedValue,
  StructuredExplanation,
  StructuredExplanationItem,
} from './MatchesDetailsModal';
import { SkillTargeterUserObject } from '@a_team/models/dist/TeamGraphObject';
import cx from 'classnames';
import { useQueryCustomTags } from '@src/rq/customTags';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
  },
  bottomMargin: {
    marginBottom: 10,
  },
  explanationContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  explanationHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 180,
    marginRight: 10,
    fontSize: 16,
    fontWeight: 500,
  },
  explanationIcon: {
    marginRight: 10,
    marginBottom: 5,
  },
  explanationContent: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    margin: 0,
    listStyleType: 'none',
    paddingInlineStart: 0,
    gap: 8,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  itemTitle: {
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  itemSubtitleText: {
    marginLeft: 29,
  },
  debugHeader: {
    marginTop: 5,
    marginBottom: 5,
  },
  debugTitle: {
    marginTop: 5,
    marginBottom: 5,
    color: 'gray',
    fontWeight: 'normal!important',
  },
  horizontalRule: {
    marginTop: 10,
    marginBottom: 10,
  },
  preformattedText: {
    display: 'flex',
    marginBottom: 15,
  },
  semanticMatchHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  semanticMatchSeparator: {
    width: 20,
    height: 1,
    backgroundColor: BorderColors.lighter,
  },
  semanticMatchQuery: {
    color: TextColors.lighter,
  },
  highlightedText: {
    backgroundColor: Colors.backgroundPurple,
    borderRadius: BorderRadius.default,
    padding: '2px 4px',
    fontWeight: FontWeights.medium,
  },
  explanationTitle: {
    whiteSpace: 'nowrap',
  },
  linkIcon: {
    cursor: 'pointer',
    width: 16,
    height: 16,
  },
  tags: {
    flexWrap: 'wrap',
  },
  tag: {
    padding: 10,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
  },
  buttonPurple: {
    color: Colors.secondaryDark,
    fontWeight: FontWeights.semiBold,
    fontSize: FontSizes.medium,
    cursor: 'pointer',
    paddingTop: Spacing.small,
    paddingBottom: Spacing.small,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: Spacing.small,
  },
  buttonIcon: {
    cursor: 'pointer',
  },
});

enum StructuredExplanationTitle {
  AboutMe = 'About Me',
  Projects = 'Projects',
  Jobs = 'Jobs',
  Missions = 'Missions',
  DeclaredIndustries = 'Declared Industries',
  CustomTags = 'Custom Tags',
  Applications = 'Applications',
  AdminNotes = 'Admin Notes',
  EvalFeedback = 'Eval Feedback',
  TimesheetSummaries = 'Timesheet Summaries',
  Blurbs = 'Blurbs',
  Transcripts = 'Transcripts',
}

const icons: Record<string, ReactElement> = {
  [StructuredExplanationTitle.AboutMe]: (
    <Icon type={IconType.AboutMe} size={'exact'} />
  ),
  [StructuredExplanationTitle.Projects]: (
    <Icon type={IconType.Projects} size={'exact'} />
  ),
  [StructuredExplanationTitle.Jobs]: (
    <Icon type={IconType.Jobs} size={'exact'} />
  ),
  [StructuredExplanationTitle.Missions]: (
    <Icon type={IconType.Missions} size={'exact'} />
  ),
  [StructuredExplanationTitle.DeclaredIndustries]: (
    <Icon type={IconType.DeclaredIndustries} size={'exact'} />
  ),
  [StructuredExplanationTitle.CustomTags]: (
    <Icon type={IconType.CustomTags} size={'exact'} />
  ),
  [StructuredExplanationTitle.Applications]: (
    <Icon type={IconType.Applications} size={'exact'} />
  ),
  [StructuredExplanationTitle.AdminNotes]: (
    <Icon type={IconType.AdminNotes} size={'exact'} />
  ),
  [StructuredExplanationTitle.EvalFeedback]: (
    <Icon type={IconType.EvaluationFeedbackForm} size={'exact'} />
  ),
  [StructuredExplanationTitle.TimesheetSummaries]: (
    <Icon type={IconType.TimesheetSummary} size={'exact'} />
  ),
  [StructuredExplanationTitle.Blurbs]: (
    <Icon type={IconType.ProposalBlurbs} size={'exact'} />
  ),
  [StructuredExplanationTitle.Transcripts]: (
    <Icon type={IconType.Transcripts} size={'exact'} />
  ),
  default: <Icon type={IconType.Edit} size={'exact'} />,
};

const titles: Record<string, string> = {
  [StructuredExplanationTitle.AboutMe]: 'About Me',
  [StructuredExplanationTitle.Projects]: 'Projects',
  [StructuredExplanationTitle.Jobs]: 'Jobs',
  [StructuredExplanationTitle.Missions]: 'Missions',
  [StructuredExplanationTitle.DeclaredIndustries]: 'Declared industries',
  [StructuredExplanationTitle.CustomTags]: 'Custom tags',
  [StructuredExplanationTitle.Applications]: 'Applications',
  [StructuredExplanationTitle.AdminNotes]: 'Admin notes',
  [StructuredExplanationTitle.EvalFeedback]: 'Evaluation feedback form',
  [StructuredExplanationTitle.TimesheetSummaries]: 'Timesheet summary',
  [StructuredExplanationTitle.Blurbs]: 'Proposal blurbs',
  [StructuredExplanationTitle.Transcripts]: 'Transcripts',
};

const structuredExplanationOrderTitles: Record<string, number> = {
  [StructuredExplanationTitle.AboutMe]: 0,
  [StructuredExplanationTitle.Projects]: 1,
  [StructuredExplanationTitle.Jobs]: 2,
  [StructuredExplanationTitle.Missions]: 3,
  [StructuredExplanationTitle.DeclaredIndustries]: 4,
  [StructuredExplanationTitle.CustomTags]: 5,
  [StructuredExplanationTitle.Applications]: 6,
  [StructuredExplanationTitle.AdminNotes]: 7,
  [StructuredExplanationTitle.EvalFeedback]: 8,
  [StructuredExplanationTitle.TimesheetSummaries]: 9,
  [StructuredExplanationTitle.Blurbs]: 10,
  [StructuredExplanationTitle.Transcripts]: 11,
};

const MAX_EXPANSION_ITEMS = 5;

export function MatchingDetailsModalBody(props: {
  user: SkillTargeterUserObject;
  semanticQuery?: string;
  matchesOn?: string;
  matchedValues?: MatchedValue[];
  structuredExplanations?: StructuredExplanation;
  esParsedExplanations?: unknown[];
  esSearchHighlight?: string[];
  matched?: boolean;
  error?: string;
}) {
  const {
    user,
    matchesOn,
    semanticQuery,
    matchedValues,
    structuredExplanations,
    esParsedExplanations,
    esSearchHighlight,
    error,
  } = props;

  const { data: allCustomTags } = useQueryCustomTags();

  const sortedStructuredExplanations = useMemo(() => {
    return [
      ...(structuredExplanations ?? []),
      { title: StructuredExplanationTitle.CustomTags, items: [] },
    ]?.sort((a, b) => {
      return (
        structuredExplanationOrderTitles[a.title] -
        structuredExplanationOrderTitles[b.title]
      );
    });
  }, [structuredExplanations]);

  const [expandedSections, setExpandedSections] = useState<
    Record<string, boolean>
  >({});

  const toggleSection = (title: string) => {
    setExpandedSections((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };

  const styles = useStyles();

  if (error) return <div>Error: {error}</div>;

  const highlightText = (text: string) => {
    return (
      '...' +
      text.replace(
        /<span class="search_service_highlighted">(.*?)<\/span>/g,
        `<span class="${styles.highlightedText}">$1</span>`,
      ) +
      '...'
    );
  };

  const shouldRenderExplanation = (
    title: string,
    matchedValues?: MatchedValue[],
  ) => {
    if (title !== StructuredExplanationTitle.CustomTags) return true;
    return (matchedValues?.length ?? 0) > 0;
  };

  const renderExplanationHeader = (title: string) => (
    <div className={styles.explanationHeader}>
      <div className={styles.explanationIcon}>
        {icons[title] ?? icons['default']}
      </div>
      <div
        className={styles.explanationTitle}
        dangerouslySetInnerHTML={{ __html: titles[title] ?? title }}
      />
    </div>
  );

  const renderCustomTags = () => {
    const customTags = matchedValues
      ?.filter((mv) => mv.type === 'customTag')
      .map((mv) => {
        const tagName = allCustomTags?.find(
          (tag) => tag._id === mv.value,
        )?.name;
        return (
          <Tag className={styles.tag} key={mv.value} color="backgroundLight">
            {tagName || 'Unknown Tag'}
          </Tag>
        );
      });
    return <TagList className={styles.tags}>{customTags}</TagList>;
  };

  const renderRegularItems = (
    title: string,
    items: StructuredExplanationItem[],
  ) => {
    const isExpanded = expandedSections[title];
    const displayedItems = isExpanded
      ? items
      : items.slice(0, MAX_EXPANSION_ITEMS);

    return (
      <div>
        {displayedItems?.map((item, ii) => {
          const { title, highlightedMatchedText, platformLink } = item ?? {};
          return (
            <div key={ii} className={styles.itemContainer}>
              <div className={styles.itemTitle}>
                {platformLink && (
                  <Icon
                    type={IconType.LinkSecondary}
                    className={styles.linkIcon}
                    onClick={() => {
                      window.open(platformLink, '_blank', 'noreferrer');
                    }}
                  />
                )}
                <span
                  dangerouslySetInnerHTML={{
                    __html: highlightText(title),
                  }}
                />
              </div>
              {highlightedMatchedText && (
                <div
                  className={styles.itemSubtitleText}
                  dangerouslySetInnerHTML={{
                    __html: highlightText(highlightedMatchedText ?? ''),
                  }}
                />
              )}
            </div>
          );
        })}
        {items.length > MAX_EXPANSION_ITEMS && (
          <div
            onClick={() => toggleSection(title)}
            className={styles.buttonPurple}
          >
            {isExpanded ? 'Collapse matches' : 'View all'}
            <Icon
              type={
                isExpanded
                  ? IconType.ArrowUpSecondary
                  : IconType.ArrowDownSecondarySmall
              }
              size={'exact'}
              className={styles.buttonIcon}
            />
          </div>
        )}
      </div>
    );
  };

  const renderExplanationContent = (
    title: string,
    items: StructuredExplanationItem[],
  ) => {
    return (
      <div className={styles.explanationContent}>
        {title === StructuredExplanationTitle.CustomTags
          ? renderCustomTags()
          : renderRegularItems(title, items)}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {!sortedStructuredExplanations?.length &&
        !user.matchedOnSemanticQuery &&
        !matchesOn && <div>No match details found</div>}
      {!!user.matchedOnSemanticQuery && (
        <div className={styles.bottomMargin}>
          <div className={cx(styles.bottomMargin, styles.semanticMatchHeader)}>
            <strong>Semantic match</strong>
            <div className={styles.semanticMatchSeparator}></div>
            {!!semanticQuery && (
              <span className={styles.semanticMatchQuery}>
                "{semanticQuery}"
              </span>
            )}
          </div>
          {user.matchedOnSemanticQuery}
        </div>
      )}
      {sortedStructuredExplanations?.map((exp, ei) => {
        const { title, items } = exp ?? {};

        if (!shouldRenderExplanation(title, matchedValues)) return null;

        return (
          <div key={ei} className={styles.explanationContainer}>
            {renderExplanationHeader(title)}
            {renderExplanationContent(title, items ?? [])}
          </div>
        );
      })}
      <hr className={styles.horizontalRule} />
      <CollapsibleContainer
        title={`Developer debug information`}
        openDefault={false}
        headerClassName={styles.debugHeader}
        titleClassName={styles.debugTitle}
      >
        <pre className={styles.preformattedText}>
          Parsed Elasticsearch Explanation:{' '}
          {JSON.stringify(esParsedExplanations, null, 2)}
        </pre>
        <hr className={styles.horizontalRule} />
        <pre className={styles.preformattedText}>
          Elasticsearch Highlight: {JSON.stringify(esSearchHighlight, null, 2)}
        </pre>
      </CollapsibleContainer>
    </div>
  );
}
