import { BasicMissionRole } from '@a_team/models/dist/MissionRole';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { Breakpoints } from '@ateams/components';
import Loader from '@src/components/Loader';
import LoadingIndicator from '@src/components/LoadingIndicator';
import ApplyDetailsModalDrawer from '@src/components/Modal/ApplyDetailsModalDrawer';
import ConfirmModalV2 from '@src/components/Modal/ConfirmModalV2';
import InviteModal from '@src/components/Modal/InviteModal';
import { SelectedUsers } from '@src/components/TeamFormationNav/SelectedUsers';
import { pluralize } from '@src/helpers/strings';
import useLoadingState from '@src/hooks/useLoadingState';
import ProfileLayout from '@src/layouts/Profile';
import { MissionApplyEditLocation, MissionPageLocation } from '@src/locations';
import { useStores } from '@src/stores';
import { ApplicationStep } from '@src/stores/Application';
import { ProfileViewMode } from '@src/stores/Profile/models';
import { loadApplication } from '@src/url-loaders/loadApplication';
import ApplicationRequirementsNotification from '@src/views/Application/ApplicationRequirementsNotification';
import RequirementsModal from '@src/views/Application/RequirementsModal';
import TeammateRecommendations from '@src/views/Application/TeammateRecommendations';
import TeammateRecommendationsV2 from '@src/views/Application/TeammateRecommendationsV2';
import Main from '@src/views/Profile/Main';
import Sidebar from '@src/views/Profile/Sidebar';
import TopBar from '@src/views/Profile/TopBar';
import cx from 'classnames';
import { observer } from 'mobx-react';
import qs from 'qs';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useScreenClass } from 'react-grid-system';
import { createUseStyles } from 'react-jss';
import { useHistory, useParams } from 'react-router-dom';
import SkipModalHeaderImage from './TeammateRecommendationsV2/SkipModalHeaderImage';
import { useMissionNotification } from './hooks/useMissionNotification';
import { InterviewAvailability } from '../InterviewSettingsViewV2/InterviewAvailability';

export const applicationViewLoader = loadApplication;
const useStyles = createUseStyles({
  selectedUsers: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    background: '#fff',
    borderRadius: 100,
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.16)',
    padding: '10px 20px',
    display: 'flex',
  },
  container: {
    paddingTop: 0,
  },
  topbar: {
    background: '#F7F7F7',
  },
  missionDetails: {
    width: '100%',
  },
  navDetailsModalShowing: {
    height: '100vh !important',
  },
  drawerWrapper: {
    height: 0,
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    color: '#222222',
    zIndex: 102,
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      opacity: 0,
      background: 'rgba(0,0,0,0.4)',
      transition: 'opacity .5s, z-index 0s .5s',
      zIndex: -1,
    },
    '&.showDetails': {
      '&::after': {
        opacity: 1,
        zIndex: 1,
        transition: 'opacity .2s',
      },
    },
  },
  drawerLow: {
    '&.isShowing': {
      top: 170,
    },
  },
  drawerHigh: {
    '&.isShowing': {
      top: 80,
    },
  },
  reqNotification: {
    marginTop: 24,
    marginBottom: 24,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    selectedUsers: {
      display: 'none',
    },
    container: {
      paddingTop: 80,
    },
    drawerWrapper: {
      width: 'calc(100% - 120px)',
      left: 120,
    },
    missionDetails: {
      width: 'calc(100% - 120px)',
    },
  },
});

const ApplicationView = (): ReactElement => {
  const styles = useStyles();
  const history = useHistory();
  const analytics = useAnalytics();
  const screenClass = useScreenClass();
  const stores = useStores();
  const [hasAvailabilitySet, setHasAvailabilitySet] = useState(false);
  const [loading, setLoading] = useLoadingState();
  const {
    auth,
    users: { profile },
    missions,
  } = stores;

  const { currentMission } = missions;
  const { aid } = useParams<{ aid: string }>();

  const suggest = useMemo(() => {
    const { suggest } = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    return suggest;
  }, [history.location.search]);

  const interviewAvailability = useMemo(() => {
    const { availability } = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    return availability;
  }, [history.location.search]);

  useEffect(() => {
    if (hasAvailabilitySet) {
      profile?.application?.gotoRecommendations();
    }
  }, [hasAvailabilitySet]);

  useEffect(() => {
    if (
      !currentMission?.mid ||
      !profile?.application?.rid ||
      !profile?.application?.aid
    ) {
      return;
    }

    const location = MissionApplyEditLocation(
      currentMission.mid,
      profile?.application.rid,
      profile.application.aid,
    );

    if (
      auth.withSharedCalendarFlow &&
      profile?.application?.step === ApplicationStep.InterviewAvailability &&
      interviewAvailability === undefined
    ) {
      history.push({
        pathname: location,
        search: '?availability=true',
      });
    } else if (
      profile?.application?.step === ApplicationStep.Recommendations &&
      suggest === undefined
    ) {
      history.push({
        pathname: location,
        search: '?suggest=true',
      });
    }
  }, [
    profile?.application?.step,
    currentMission?.mid,
    profile?.application?.rid,
    profile?.application?.aid,
    history.push,
  ]);

  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [inviteRole, setInviteRole] = useState<BasicMissionRole | null>(null);
  const highDrawerTopOffset =
    profile?.application?.step === ApplicationStep.Application ||
    screenClass !== 'xs';

  const isNewApplication = !aid || suggest !== undefined;

  const totalOpenRoles = useMemo(() => {
    return currentMission?.openRoleTitles.length ?? 0;
  }, [currentMission?.data.roles]);

  useMissionNotification(isNewApplication, totalOpenRoles);

  const style = useMemo(() => {
    return {
      padding: screenClass === 'xs' ? '0 24px' : '20px 40px',
      background: '#fff',
    };
  }, [screenClass]);

  const showingDrawer = useMemo(() => {
    return !!profile?.application?.detailsMenuOpen;
  }, [profile?.application?.detailsMenuOpen]);

  const blockNavigation = useMemo(() => {
    if (
      profile?.application?.submitted ||
      profile?.application?.step === ApplicationStep.Recommendations ||
      profile?.application?.step === ApplicationStep.InterviewAvailability
    )
      return false;
    return (
      !profile?.application?.readonly &&
      (!!profile?.isDirty || profile?.application?.isDirty)
    );
  }, [
    profile?.application?.step,
    profile?.application?.submitted,
    profile?.application?.readonly,
    profile?.application?.isDirty,
    profile?.isDirty,
  ]);

  useEffect(() => {
    return () => {
      profile?.setApplication(null);
      profile?.setProfileMode(ProfileViewMode.View);
      profile?.setInitialData();
    };
  }, [profile]);

  const onVideoPlay = useCallback(() => {
    currentMission &&
      analytics.trackMissionVideoPlayed(currentMission?.data, 'details');
  }, []);

  const onNavigate = useCallback(
    (path: string) => {
      // Reset data and continue to other route
      profile?.setInitialData();
      history.push(path);
    },
    [profile],
  );

  useEffect(() => {
    if (interviewAvailability !== undefined) {
      profile?.application?.setApplicationStep(
        ApplicationStep.InterviewAvailability,
      );
    } else if (suggest !== undefined) {
      profile?.application?.setApplicationStep(ApplicationStep.Recommendations);
    }
  }, [
    suggest,
    interviewAvailability,
    profile?.application?.setApplicationStep,
  ]);

  useEffect(() => {
    window.onmessage = function (e) {
      if (e.data === 'USER_AVAILABILITY_UPDATED') {
        setHasAvailabilitySet(true);
      }
    };
  }, []);

  const onApplySuccessClose = useCallback(() => {
    currentMission && history.push(MissionPageLocation(currentMission?.mid));
  }, [currentMission?.mid]);

  const onInviteModalClose = () => {
    setInviteRole(null);
    setInviteModalOpen(false);
  };

  const currentRole = currentMission?.data.roles.find(
    (role) => role.rid === profile?.application?.rid,
  );

  const titleText = {
    withTeammates: `You applied to the mission and invited ${pluralize(
      profile?.application?.requestedTeammates.length,
      'builder',
    )} to join you`,
    noTeammates: 'Nice! You applied to the mission.',
    edit: 'You edited your application',
  };

  const mainText = {
    withTeammates:
      'If your teammates aren’t accepted, you’ll still be considered as a solo builder. \n' +
      '\n' +
      'You can expect to hear from us within 48 hours. In the meantime, we encourage you to apply for other missions.',
    noTeammates:
      'You can expect to hear from us within 48 hours. In the meantime, we encourage you to apply for other missions.',
    edit: 'Our team formation specialists will see your edits and be in touch with an update soon. In the meantime, we encourage you to apply for other missions.',
  };

  const isViewModeOnly =
    auth.withViewPastApplications && missions.isViewModeOnly;

  const renderInterAvailability = () => {
    if (!auth.withSharedCalendarFlow) {
      return null;
    }

    if (!profile?.application?.isInterviewAvailabilityStep) {
      return null;
    }

    return (
      <InterviewAvailability
        title="Confirm your interview availability"
        copy="Since you haven’t connected your calendar to check for conflicts, companies can schedule interviews at any of the times you select below. Please keep this updated to reflect your interview availability."
      />
    );
  };

  return (
    <>
      <ProfileLayout
        title={
          currentMission
            ? `Apply to ${currentMission.data.title}`
            : 'Loading...'
        }
        style={style}
        containerClassName={styles.container}
        topNav={null}
        hero={<TopBar className={styles.topbar} fixed />}
        sidebar={
          (profile?.application?.isRecommendationsStep && totalOpenRoles > 1) ||
          profile?.application?.isInterviewAvailabilityStep ? null : (
            <Sidebar />
          )
        }
        blockNavigation={isViewModeOnly ? false : blockNavigation}
        navigate={onNavigate}
      >
        {profile?.application && (
          <>
            <RequirementsModal
              open={!!profile.application.requirementsModalOpen}
              onClose={() =>
                profile?.application?.setRequirementsModalOpen(false)
              }
              onSubmit={() =>
                setLoading(
                  profile.saveApplication(),
                  'Your application was successfully submitted',
                )
              }
            />
            <ConfirmModalV2
              dataTestingId="mission-application-success-modal"
              image={<SkipModalHeaderImage />}
              title={
                <div
                  style={{
                    maxWidth: 500,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  {!isNewApplication && titleText['edit']}

                  {isNewApplication &&
                    profile?.application?.requestedTeammates.length === 0 &&
                    titleText['noTeammates']}

                  {isNewApplication &&
                    profile?.application?.requestedTeammates.length > 0 &&
                    titleText['withTeammates']}
                </div>
              }
              description={
                <>
                  {profile.application.shouldShowRequirementsMissingWarning ? (
                    <>
                      <ApplicationRequirementsNotification
                        text={
                          'Your application is missing multiple nice to haves. We’ll still review it, but there’s a lower chance of acceptance.'
                        }
                        className={styles.reqNotification}
                      />
                      While your application is in review, we encourage you to
                      apply for other missions.
                    </>
                  ) : (
                    <>
                      {!isNewApplication && mainText['edit']}

                      {isNewApplication &&
                        profile?.application?.requestedTeammates.length === 0 &&
                        mainText['noTeammates']}

                      {isNewApplication &&
                        profile?.application?.requestedTeammates.length > 0 &&
                        mainText['withTeammates']}
                    </>
                  )}
                </>
              }
              open={
                !auth.withTeamUp &&
                profile?.application.submitted &&
                (!isNewApplication ||
                  totalOpenRoles <= 1 ||
                  profile?.application?.step ===
                    ApplicationStep.Recommendations)
              }
              onClose={onApplySuccessClose}
              onConfirm={onApplySuccessClose}
              onConfirmText="See more missions"
              onConfirmColor="secondaryDark"
            />
          </>
        )}
        {auth.user && (
          <InviteModal
            currentUser={auth.user}
            open={inviteModalOpen}
            openMissionRole={inviteRole || undefined}
            onClose={onInviteModalClose}
          />
        )}
        {renderInterAvailability()}
        {profile?.application ? (
          <>
            {profile?.application?.isRecommendationsStep &&
            totalOpenRoles > 1 ? (
              <>
                {currentRole ? (
                  <>
                    {currentMission?.mid && profile?.application?.rid && (
                      <TeammateRecommendationsV2
                        missionId={currentMission.mid}
                        currentRole={currentRole}
                        requestedTeammates={
                          profile?.application?.requestedTeammates
                        }
                        onRequest={profile?.application?.onTeammateRequest}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <TeammateRecommendations
                      cards={profile?.application?.suggestedTeammates}
                      requestedTeammates={
                        profile?.application?.requestedTeammates
                      }
                      onRequest={(card, requested) =>
                        profile?.application?.onTeammateRequest(
                          card.uid,
                          requested,
                        )
                      }
                      onSearch={profile?.application?.searchTeammateSuggestions}
                      searchResults={
                        profile?.application?.teammateSearchResults
                      }
                      currentRole={currentRole || null}
                    />
                  </>
                )}
                {!auth.withTeamUp &&
                  profile?.application?.requestedUsers.length > 0 && (
                    <SelectedUsers
                      users={profile?.application?.requestedUsers}
                      className={styles.selectedUsers}
                    />
                  )}
              </>
            ) : profile.application.isInterviewAvailabilityStep ? null : (
              <Main />
            )}
          </>
        ) : (
          <Loader />
        )}
      </ProfileLayout>
      <div
        className={cx(styles.drawerWrapper, {
          [styles.navDetailsModalShowing]: showingDrawer,
          showDetails: showingDrawer,
        })}
      >
        <ApplyDetailsModalDrawer
          description={currentMission?.data.description}
          attachedLinks={
            currentMission?.data.attachedLinks
              ? currentMission?.data.attachedLinks
              : undefined
          }
          onVideoPlay={onVideoPlay}
          videoURL={currentMission?.data.videoURL}
          isShowing={showingDrawer}
          className={cx(styles.missionDetails, styles.drawerLow, {
            [styles.drawerHigh]: highDrawerTopOffset,
          })}
        />
      </div>
      <LoadingIndicator loading={loading} />
    </>
  );
};

export default observer(ApplicationView);
