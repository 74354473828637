import { ServiceAuth, ServiceEndpoint } from './utils';
import {
  CurrentUserObject,
  RegisteredUserObject,
  UserId,
  UserUsername,
} from '@a_team/models/dist/UserObject';
import ApiToken from '@a_team/models/dist/ApiToken';
import { UTMParams } from './Registration';

export const BasePath = '/auth';

export interface LoginData {
  email: string;
  password: string;
  unsafe?: boolean;
}

export enum LoginWithMethod {
  InviteEmail = 'InviteEmail',
  // LinkedIn = 'LinkedIn',
  Google = 'Google',
  Facebook = 'Facebook',
  OnboardingToken = 'OnboardingToken',
  OAuth = 'OAuth',
}

export interface OAuthStartRequest {
  provider: 'facebook' | 'google' | 'github';
}

export interface OAuthStartResponse {
  redirectURL: string;
}

export interface LoginWithCredentials {
  method: LoginWithMethod;
  payload: string;
  utmParams?: UTMParams;
  clientId?: string;
}

export interface PasswordResetRequest {
  email: string;
  subdomain?: string;
}

export interface PasswordResetPayload {
  hashCode: string;
  password: string;
}

export enum RegistrationResultType {
  CANDIDATE_VERIFICATION_PENDING = 'CANDIDATE_VERIFICATION_PENDING',
  CREATED_CANDIDATES_THRESHOLD_EXCEEDED = 'CREATED_CANDIDATES_THRESHOLD_EXCEEDED',
  ONBOARDING_TOKEN_SENT = 'ONBOARDING_TOKEN_SENT',
  CANDIDATE_CREATED = 'CANDIDATE_CREATED',
  CANDIDATE_VERIFIED = 'CANDIDATE_VERIFIED',
  CANDIDATE_ACTIVATED = 'CANDIDATE_ACTIVATED',
  ONBOARDING_ALREADY_COMPLETED = 'ONBOARDING_ALREADY_COMPLETED',
  USER_DELETED = 'USER_DELETED',
  USER_REJECTED = 'USER_REJECTED',
  USER_ACTIVE = 'USER_ACTIVE',
}

export interface BuilderOnboardingStateModel {
  token?: string;
  resultType: RegistrationResultType;
}

export interface LoginResponse {
  token: ApiToken;
  user: CurrentUserObject | RegisteredUserObject;
  refreshToken: ApiToken;
  onboardingState?: BuilderOnboardingStateModel;
}

export interface RenewTokenResponse {
  token: ApiToken;
}

export interface RefreshTokenResponse {
  accessToken: ApiToken;
  refreshToken: ApiToken;
}

// exported functions

export default class AuthEndpoint extends ServiceEndpoint {
  public login(data: LoginData): Promise<LoginResponse> {
    return this.fetch(null, BasePath + '/login', null, 'post', data);
  }

  public oauthStart(data: OAuthStartRequest): Promise<OAuthStartResponse> {
    return this.fetch(null, BasePath + '/oauth', null, 'post', data);
  }

  public loginWith(data: LoginWithCredentials): Promise<LoginResponse> {
    return this.fetch(null, BasePath + '/login-with', null, 'post', data);
  }

  public requestPasswordReset(data: PasswordResetRequest): Promise<void> {
    return this.fetch(null, BasePath + '/reset-password', null, 'post', data);
  }

  public resetPassword(
    uid: UserId,
    data: PasswordResetPayload,
  ): Promise<LoginResponse> {
    return this.fetch(
      null,
      BasePath + `/reset-password/${uid}`,
      null,
      'post',
      data,
    );
  }

  public renewToken(auth: ServiceAuth): Promise<RenewTokenResponse> {
    return this.fetch(auth, BasePath + '/renew-token', null, 'post');
  }

  public loginAs(
    auth: ServiceAuth,
    username: UserUsername,
  ): Promise<LoginResponse> {
    return this.fetch(auth, BasePath + `/login-as/${username}`, null, 'post');
  }

  public logout(auth: ServiceAuth): Promise<null> {
    return this.fetch(auth, BasePath + '/logout', null, 'post');
  }

  /** @experimental **/
  public formationRequestPasswordReset(
    data: PasswordResetRequest,
  ): Promise<void> {
    return this.fetch(
      null,
      BasePath + '/formation-reset-password',
      null,
      'post',
      data,
    );
  }
}
