import { Breakpoints } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface FinishSetupProps {
  openModal: () => void;
}

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    padding: '160px 16px 60px 16px',
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 35.11%)',
    zIndex: 1,
  },
  content: {
    width: '100%',
    maxWidth: 600,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '32px',
  },
  description: {
    maxWidth: 400,
    fontSize: 15,
  },
  button: {
    color: '#6D00D7',
    cursor: 'pointer',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    title: {
      width: 'max-content',
    },
    container: {
      padding: '228px 434px 34px 434px',
    },
  },
});

const FinishSetup = ({ openModal }: FinishSetupProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          Finish setup to get access to Discovery
        </div>
        <div className={styles.description}>
          Once you finish setup and get approved into the network, you'll have
          full access to discovery.
        </div>
        <div className={styles.button} onClick={openModal}>
          Finish setup {'->'}
        </div>
      </div>
    </div>
  );
};

export default FinishSetup;
