import { DateISOString, QueryResult } from './misc';
import { BasicMissionObject } from './MissionObject';
import { ApiTokenPayload } from './ApiToken';
import MissionApplicationObject, {
  ApplicantStatus,
} from './MissionApplicationObject';
import { MissionRoleStatus } from './MissionRole';
import { AdminVettingProcessWithAudit } from './vetting-processes/vetting-processes';
import { VetterConfigurationObject } from './vetter';
import { BasicUserObject, ScrubReason } from './UserObject';

export type TimesheetsSummary = {
  startDate: Date;
  endDate: Date;
  totalMinutes: number;
};

export declare type TimesheetsDetails = {
  startDate: Date;
  endDate: Date;
  summary: string;
  demoLink: string;
};

export enum AdminNoteCategory {
  Admin = 'admin',
  Migration = 'migration',
  TeamPulse = 'team-pulse',
  ProposalFeedback = 'proposal-feedback',
  ApplicationReview = 'application-review',
  Vetting = 'vetting',
  ProposalBlurb = 'proposal-blurb',
  MissionFeedback = 'mission-feedback',
}

export type AdminNoteId = string;

export type AdminNotesScore = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

// Labeled Expertise
export enum ExpertiseScore {
  Unqualified = 0,
  Junior = 1,
  Adequate = 3,
  Good = 4,
  VeryGood = 5,
  Superstar = 7,
}

// Labeled Interpersonal Skills
export enum InteractionExperienceScore {
  Unqualified = 0,
  Problematic = 1,
  Adequate = 3,
  Good = 4,
  Excellent = 7,
}

// Labeled English Proficiency
export enum EnglishLevel {
  None = 0,
  Limited = 3,
  Good = 6,
  Native = 7,
}

// Labeled English Pronunciation
export enum AccentScore {
  Weak = 0,
  Adequate = 2,
  Good = 6,
  Excellent = 7,
}

export interface BuilderPaymentCycleSummary {
  startDate?: DateISOString;
  endDate?: DateISOString;
  minutes: number;
}

export interface AdminPitch {
  author: Pick<ApiTokenPayload, 'fullName' | 'username' | 'profilePictureURL'>;
  updatedAt: DateISOString;
  text: string;
}

export interface AdminNote extends AdminPitch {
  nid: AdminNoteId;
  createdAt: DateISOString;
  category?: AdminNoteCategory;
}

export interface AdminNotesTranscript {
  recordingUrl: string;
  jobId: string;
}

export interface AdminNotesMissionApplicationInterview {
  interviewDate: DateISOString;
  interviewFeedback?: {
    action?: string;
    note?: string;
  };
  transcripts?: AdminNotesTranscript[];
}

export type AdminNotesMissionApplication = Pick<
  MissionApplicationObject,
  | 'aid'
  | 'rid'
  | 'status'
  | 'roleCategory'
  | 'availability'
  | 'workingHours'
  | 'hourlyRateRange'
  | 'monthlyRateRange'
  | 'proposedRates'
  | 'pitch'
  | 'about'
  | 'changes'
  | 'createdAt'
  | 'updatedAt'
  | 'withdrawn'
> &
  Omit<ApplicantStatus, 'vettingScheduled'> & {
    mission: BasicMissionObject;
    roleStatus: MissionRoleStatus;
    roleUser?: BasicUserObject | null;
    roleHeadline?: string;
    roleRequiredSkills?: string[];
    lastStatusChangeWasAutomatic?: boolean;
    companyName?: string;
    interviews?: AdminNotesMissionApplicationInterview[];
    roleBuilderHourlyRateMin?: number;
    roleBuilderHourlyRateMax?: number;
    roleBuilderMonthlyRateMin?: number;
    roleBuilderMonthlyRateMax?: number;
  };

export interface AdminNotesMission extends BasicMissionObject {
  builderFirstName?: string;
  builderHourlyRate?: number;
  builderTotalMinutes?: number;
  missionStartDate?: DateISOString;
  missionEndDate?: DateISOString;
  missionScheduledEndDate?: DateISOString;
  builderStartDate?: DateISOString;
  builderEndDate?: DateISOString;
  lastCycle?: BuilderPaymentCycleSummary;
  secondLastCycle?: BuilderPaymentCycleSummary;
  thirdLastCycle?: BuilderPaymentCycleSummary;
  minHours?: number;
  roleCategoryTitle?: string;
  companyName?: string;
  roleStatus?: MissionRoleStatus;
  rid?: string;
  hasPerformanceIssue?: boolean;
  roleRequiredSkills?: string[];
  roleHeadline?: string;
  isFullTimeRetainer?: boolean;
  timesheets?: TimesheetsDetails[];
}

export interface AdminNotesDiscoverySignal {
  accountId: string;
  workspaceName: string;
  starredBy: string;
  starredAt: DateISOString;
  starredContext: string;
}

export interface AdminNotesDiscoveryInterview {
  accountId: string;
  workspaceName: string;
  interviewStatus?: string;
  interviewDate?: DateISOString;
  roleDescription?: string;
  minCompanyBudget?: number;
  maxCompanyBudget?: number;
  transcripts?: AdminNotesTranscript[];
}

export interface AdminNotesDiscovery {
  clientSignals: AdminNotesDiscoverySignal[];
  clientInterviews: AdminNotesDiscoveryInterview[];
}

export default interface AdminNotesObject {
  aboutMe?: string;
  phoneNumber?: string;
  pitch?: AdminPitch;
  notes: AdminNote[];
  missions: AdminNotesMission[];
  applications: QueryResult<AdminNotesMissionApplication>;
  builderHourlyRate?: number;
  latestTimesheetsSummaries: TimesheetsSummary[];
  totalMinutesBilled: number;
  scores?: {
    expertise?: AdminNotesScore;
    interactionExperience?: AdminNotesScore;
    english?: AdminNotesScore;
    accent?: AdminNotesScore;
  };
  preventPrepay?: boolean;
  vettingProcesses: AdminVettingProcessWithAudit[];
  vetterConfiguration?: VetterConfigurationObject;
  markedAsRejectedAt?: DateISOString;
  rejectionEmailSentAt?: DateISOString;
  scrubbedMinusOneReasons?: ScrubReason[];
  discovery?: AdminNotesDiscovery;
  estimatedAvailability?: number;
}

export type ScoreLabelsMap = Partial<Record<AdminNotesScore, string>>;

export const ExpertiseScoreLabels: ScoreLabelsMap = {
  [ExpertiseScore.Unqualified]: 'Unqualified',
  [ExpertiseScore.Junior]: 'Junior',
  [ExpertiseScore.Adequate]: 'Adequate',
  [ExpertiseScore.Good]: 'Good',
  [ExpertiseScore.VeryGood]: 'Very good',
  [ExpertiseScore.Superstar]: 'Superstar',
};

export const InteractionExperienceLabels: ScoreLabelsMap = {
  [InteractionExperienceScore.Unqualified]: 'Unqualified',
  [InteractionExperienceScore.Problematic]: 'Problematic',
  [InteractionExperienceScore.Adequate]: 'Adequate',
  [InteractionExperienceScore.Good]: 'Good',
  [InteractionExperienceScore.Excellent]: 'Excellent',
};

export const EnglishLevelLabels: ScoreLabelsMap = {
  [EnglishLevel.None]: 'None',
  [EnglishLevel.Limited]: 'Limited',
  [EnglishLevel.Good]: 'Good',
  [EnglishLevel.Native]: 'Native or functionally native',
};

export const AccentScoreLabels: ScoreLabelsMap = {
  [AccentScore.Weak]: 'Weak',
  [AccentScore.Adequate]: 'Adequate',
  [AccentScore.Good]: 'Good',
  [AccentScore.Excellent]: 'Excellent',
};

export enum ScoreType {
  Expertise = 'expertise',
  InteractionExperience = 'interactionExperience',
  English = 'english',
  Accent = 'accent',
}

export const ScoreTypeLabels: Record<ScoreType, string> = {
  [ScoreType.Expertise]: 'Expertise level',
  [ScoreType.InteractionExperience]: 'Interpersonal skills',
  [ScoreType.English]: 'English proficiency',
  [ScoreType.Accent]: 'English pronunciation',
};

export const ScoreLabels: Record<ScoreType, ScoreLabelsMap> = {
  [ScoreType.Expertise]: ExpertiseScoreLabels,
  [ScoreType.InteractionExperience]: InteractionExperienceLabels,
  [ScoreType.English]: EnglishLevelLabels,
  [ScoreType.Accent]: AccentScoreLabels,
};
