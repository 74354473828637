import React, { ReactElement } from 'react';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import MainLayout, { MainLayoutProps } from '../Main';
import ShareModal from '@src/components/Modal/ShareModal';
import ConfirmModal from '@src/components/Modal/ConfirmModal';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useToggle from '@src/hooks/useToggle';
import { useHistory } from 'react-router-dom';
import useLoadingState from '@src/hooks/useLoadingState';
import { MissionMatch } from '@src/url-loaders/loadMission';
import { MissionControlBase, TargeterLocation } from '@src/locations';
import { MissionStatus } from '@a_team/models/dist/MissionObject';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';
import { useScreenClass } from 'react-grid-system';
import NewMissionNavBar from './NewMissionNavbar';
import AutomatedReachoutModal from '@src/views/Mission/EditMission/AutomatedReachoutModal';

interface Props extends MainLayoutProps {
  match: MissionMatch;
}

const useStyles = createUseStyles({
  alert: {
    color: '#fff',
    padding: '8px',
    fontSize: '14px',
    background: '#62646A',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    margin: 0,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    alert: {
      color: '#fff',
      padding: '8px',
      fontSize: '14px',
      background: '#62646A',
      position: 'fixed',
      bottom: 0,
      width: 'calc(100% - 120px)',
      textAlign: 'center',
      margin: 0,
      left: 120,
    },
  },
});

function MissionLayout(props: Props): ReactElement | null {
  const { match, title, ...leftProps } = props;
  const styles = useStyles();

  const [modal, toggleModal] = useToggle();
  const { auth, missions } = useStores();
  const screenClass = useScreenClass();
  const { currentMission } = missions;
  const history = useHistory();
  const [deleteModal, toggleDelete] = useToggle();
  const [publishModal, togglePublish] = useToggle();
  const [loading, setLoading] = useLoadingState();

  const isVisible =
    !currentMission?.data.hidden && !!currentMission?.data.publishedAt;

  const isPublished = currentMission?.data.status === MissionStatus.Published;

  const handleDelete = (): void => {
    if (!currentMission) return;

    toggleDelete(false);

    setLoading(
      missions.delete(auth, currentMission.mid).then((): void => {
        history.replace(MissionControlBase);
      }),
    );
  };

  const handlePublish = (): void => {
    if (!currentMission) return;

    togglePublish(false);
    setLoading(missions.publish(auth, currentMission.mid), 'Published!');
  };

  const onSaveWithReachoutRedirect = (): void => {
    if (!currentMission) return;
    setLoading(
      missions.publish(auth, currentMission.mid).then(() => {
        history.push(
          `${TargeterLocation}?loadFromTabCache=true&mid=${currentMission.mid}`,
        );
      }),
      'Published!',
    );
  };

  const onReachoutRedirect = (): void => {
    if (!currentMission) return;
    history.push(
      `${TargeterLocation}?loadFromTabCache=true&mid=${currentMission.mid}`,
    );
  };

  return (
    <>
      <MainLayout
        title={title || currentMission?.data.title}
        topNav={
          <NewMissionNavBar
            toggleShareModal={toggleModal}
            toggleDeleteModal={toggleDelete}
            togglePublish={togglePublish}
          />
        }
        style={
          screenClass === 'xs' ? { marginTop: 16, padding: 16 } : undefined
        }
        {...leftProps}
      />
      <ShareModal
        open={modal}
        title={'Share Mission'}
        description="Share the mission with a builder you think is up for the challenge. They'll need to be a member of A.Team to view the mission."
        onClose={toggleModal}
        location={match.url}
      />
      <ConfirmModal
        title="Delete this Mission"
        description="Are you sure you want to permanently delete this mission?"
        onConfirm={handleDelete}
        open={deleteModal}
        onClose={toggleDelete}
      />
      <AutomatedReachoutModal
        open={publishModal}
        onClose={togglePublish}
        onSaveWithoutReachout={!isPublished ? handlePublish : undefined}
        onSaveWithReachoutRedirect={
          !isPublished ? onSaveWithReachoutRedirect : undefined
        }
        onReachoutRedirect={onReachoutRedirect}
        saveAndOpenInTargeterLabel="Publish and open in targeter"
        saveWithoutReachoutLabel="Publish"
        saveWithReachoutLabel="Publish and reachout"
        missionId={currentMission?.mid}
      />
      <LoadingIndicator loading={loading} />
      <div>
        {currentMission?.data.status !== MissionStatus.Running && !isVisible ? (
          <div className={styles.alert}>
            {currentMission?.data.publishedAt
              ? 'This mission is in stealth, you can’t share it with your friends.'
              : `The project is under review.`}
          </div>
        ) : null}
      </div>
    </>
  );
}

export default observer(MissionLayout);
