import React, { useRef, useState } from 'react';
import cx from 'classnames';
import { Icon } from '@a_team/ui-components';
import grayCoin from './grayCoin.svg';
import { useInviteStyles } from '.';
import { createUseStyles } from 'react-jss';
import xLogo from './xLogo.svg';
import linkedinLogo from './linkedinLogo.svg';
import facebookLogo from './facebookLogo.svg';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { useInviteMutation } from '@src/rq/invite';
import { InvitationRequestData } from '@ateams/api/dist/endpoints/Invitations';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { useStores } from '@src/stores';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { Breakpoints } from '@ateams/components';

const useStyles = createUseStyles({
  shareButton: {
    width: '100%',
    padding: '12px 16px',
    border: 'none',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    background: '#EFEFF0',
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    position: 'relative',
    overflow: 'hidden',
    height: 45,
  },
  linkButton: {
    color: '#222',
  },
  socialShare: {
    display: 'flex',
    gap: 16,
    marginTop: 24,
  },
  shareContainer: {
    width: '100%',
    marginTop: 24,
    marginBottom: 24,
  },
  linkedinInputWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '12px 16px',
    fontSize: '16px',
    border: '1px solid #E5E5E5',
    borderRadius: '8px',
    '&:focus-within': {
      outline: 'none',
    },
  },
  linkedinPrefix: {
    color: '#000',
  },
  shareButtonText: {
    transition: 'opacity 0.3s ease, transform 0.3s ease',
    position: 'absolute',
    width: '100%',
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  shareButtonTextVisible: {
    opacity: 1,
    transform: 'translateY(0)',
  },
  shareButtonTextHidden: {
    opacity: 0,
    transform: 'translateY(20px)',
  },
  linkedinInput: {
    border: 'none',
    padding: 0,
    margin: 0,
    flex: 1,
    fontSize: 'inherit',
    background: 'transparent',
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: '#9CA3AF',
    },
  },
  hiddenMobile: {
    display: 'none',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    hiddenMobile: {
      display: 'block',
    },
  },
});

function parseLinkedInUsername(input: string): string | undefined {
  return (
    input
      .trim()
      .replace(/^((https?:\/\/)?(www\.)?linkedin\.com\/in)?\/|[/]+$/g, '') ||
    undefined
  );
}

const schema = z.object({
  builderName: z.string().min(1, 'Builder name is required'),
  builderEmailAddress: z.string().email('Invalid email address'),
  linkedin: z.string().optional(),
});

type BuilderFormValues = z.infer<typeof schema>;

const BuilderForm = () => {
  const commonStyles = useInviteStyles();
  const styles = useStyles();
  const [isHovered, setIsHovered] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');
  const analytics = useAnalytics();
  const { auth } = useStores();

  const inviteMutation = useInviteMutation();

  const [loadingState, setLoadingState] = useState<boolean | string | null>(
    null,
  );

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<BuilderFormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      builderName: '',
      builderEmailAddress: '',
      linkedin: '',
    },
  });

  const onSubmit = (formData: BuilderFormValues) => {
    const invitationData: InvitationRequestData = {
      fullName: formData.builderName,
      email: formData.builderEmailAddress,
      linkedInUsername: formData.linkedin || undefined,
    };

    inviteMutation.mutate(invitationData, {
      onSuccess: () => {
        analytics.trackInviteModalSubmitted({
          email: formData.builderEmailAddress,
          fullName: formData.builderName,
          linkedInUsername: formData.linkedin,
        });
        reset();
        setInputValue('');
      },
      onError: (error) => {
        console.error('Error sending invitation', error);
      },
    });
  };

  const shareLink = `https://invite.a.team/${auth.username}`;

  const handleCopyClick = async () => {
    setLoadingState(true);
    try {
      navigator.clipboard.writeText(shareLink).then(() => {
        console.info('URL copied to clipboard');
      });
      setLoadingState('Copied!');
      setTimeout(() => setLoadingState(null), 2000);
    } catch (error) {
      setTimeout(() => setLoadingState(null), 2000);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className={cx(
          commonStyles.companyBuilderFormContainer,
          commonStyles.builderFormContainer,
        )}
      >
        <img src={grayCoin} alt="grayCoin" />
        <div className={commonStyles.formTitle}>Invite builders to A.Team</div>
        <div className={cx(commonStyles.body, commonStyles.greyText)}>
          Great teams are built by people who love working together. Share your
          referral code to invite builders you'd like to team up with on an
          A.Team mission
        </div>

        <div className={styles.shareContainer}>
          <button
            type="button"
            className={styles.shareButton}
            onClick={handleCopyClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <span
              className={cx(
                styles.shareButtonText,
                isHovered
                  ? styles.shareButtonTextHidden
                  : styles.shareButtonTextVisible,
              )}
            >
              <Icon
                name="copy"
                size="sm"
                className={commonStyles.icon}
                style={{ marginRight: 8 }}
              />
              {shareLink}
            </span>
            <span
              className={cx(
                styles.shareButtonText,
                isHovered
                  ? styles.shareButtonTextVisible
                  : styles.shareButtonTextHidden,
              )}
            >
              Copy the URL to the clipboard
            </span>
          </button>

          <div className={styles.socialShare}>
            <a
              className={cx(styles.shareButton, styles.linkButton)}
              href={`https://twitter.com/intent/tweet?&url=${encodeURI(
                shareLink,
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={xLogo} alt="xLogo" />
              <span className={styles.hiddenMobile}>X.com</span>
            </a>
            <a
              className={cx(styles.shareButton, styles.linkButton)}
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(
                shareLink,
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedinLogo} alt="linkedinLogo" />
              <span className={styles.hiddenMobile}>LinkedIn</span>
            </a>
            <a
              className={cx(styles.shareButton, styles.linkButton)}
              href={`https://www.facebook.com/sharer.php?u=${encodeURI(
                shareLink,
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebookLogo} alt="facebookLogo" />
              <span className={styles.hiddenMobile}>Facebook</span>
            </a>
          </div>
        </div>

        <div className={commonStyles.formGroup}>
          <label htmlFor="builderName" className={commonStyles.label}>
            Builder name
          </label>
          <input
            id="builderName"
            className={commonStyles.input}
            placeholder="Full name"
            {...register('builderName')}
          />
          {errors.builderName && (
            <span className={commonStyles.error}>
              {errors.builderName.message}
            </span>
          )}
        </div>

        <div className={commonStyles.formGroup}>
          <label htmlFor="builderEmailAddress" className={commonStyles.label}>
            Email address
          </label>
          <input
            id="builderEmailAddress"
            className={commonStyles.input}
            placeholder="Email address"
            {...register('builderEmailAddress')}
          />
          {errors.builderEmailAddress && (
            <span className={commonStyles.error}>
              {errors.builderEmailAddress.message}
            </span>
          )}
        </div>

        <div className={commonStyles.formGroup}>
          <label htmlFor="linkedin" className={commonStyles.label}>
            LinkedIn (optional)
          </label>
          <Controller
            name="linkedin"
            control={control}
            render={({ field }) => (
              <div
                className={styles.linkedinInputWrapper}
                onClick={() => inputRef.current?.focus()}
              >
                <span className={styles.linkedinPrefix}>linkedin.com/in/</span>
                <input
                  {...field}
                  ref={inputRef}
                  id="linkedin"
                  className={styles.linkedinInput}
                  placeholder="username..."
                  value={inputValue}
                  onChange={(e) => {
                    const parsed = parseLinkedInUsername(e.target.value);
                    setInputValue(parsed || '');
                    field.onChange(parsed);
                  }}
                />
              </div>
            )}
          />
          {errors.linkedin && (
            <span className={commonStyles.error}>
              {errors.linkedin.message}
            </span>
          )}
        </div>

        <div className={commonStyles.btnContainerBuilder}>
          <div className={commonStyles.featured}>
            <Icon name="details" size="sm" className={commonStyles.icon} />
            You will be featured on their profile page.
          </div>
          <button type="submit" className={commonStyles.submitButton}>
            {inviteMutation.isLoading ? 'Sending...' : 'Send invite'}
          </button>
        </div>
      </div>

      <LoadingIndicator loading={loadingState} />

      <LoadingIndicator
        loading={
          inviteMutation.isSuccess ? 'The invitation has been sent' : null
        }
      />

      <LoadingIndicator
        loading={inviteMutation.isError ? inviteMutation.error : null}
      />
    </form>
  );
};

export default BuilderForm;
